import React from "react";
import { Separator, Text } from "react-aria-components";
import {
  allOptionsSelected,
  GetFilterButtonText,
} from "../../CommonComponents/FilterButtonWithMenu/GetFilterButtonText";
import { FilterButtonWithMenu } from "../../CommonComponents/FilterButtonWithMenu/FilterButtonWithMenu";
import {
  FilterItem,
  FilterMenu,
} from "../../CommonComponents/FilterButtonWithMenu/FilterMenu";
import { DefaultJournalStatusModalBindingComponent } from "./DefaultJournalStatus";
import { useSelectedWithSelectAllLogic } from "../../CommonComponents/FilterButtonWithMenu/useSelectedWithSelectAllLogic";
import { hasSelection, Option } from "../Helpers/useFilterConfirmedState";
import { FilterSelection } from "../Helpers/FilterSelection";

export const JournalStatusFilter = ({
  options,
  confirmedSelection,
  setConfirmedSelection,
}: {
  options: Option[];
  confirmedSelection: FilterSelection;
  setConfirmedSelection: (state: Set<React.Key>) => void;
}) => {
  const [selected, onSelectionWithSelectAll] = useSelectedWithSelectAllLogic(
    options.map((option) => option.value),
    confirmedSelection
  );
  const [modalStatus, setModalStatus] = React.useState(false);
  const [openState, setOpenState] = React.useState(false);
  const filterStateText = GetFilterButtonText(confirmedSelection, options);
  // initialize the journal status with change of default journal status options
  const onDefaultJournalStatusChange = (defaultSettings: Set<React.Key>) => {
    onSelectionWithSelectAll(defaultSettings);
    setConfirmedSelection(defaultSettings);
    setOpenState(false);
  };
  const onSelectionConfirm = () => {
    setConfirmedSelection(selected);
  };

  return (
    <FilterButtonWithMenu
      buttonLabel="承認ステータス"
      buttonText={filterStateText}
      showClearIcon={hasSelection(confirmedSelection)}
      onClearSelection={() => {
        setConfirmedSelection(new Set());
        onSelectionWithSelectAll(new Set());
      }}
      onSelectionConfirm={onSelectionConfirm}
      openState={openState}
      setOpenState={setOpenState}
    >
      <FilterMenu
        selectionMode="multiple"
        selectedKeys={selected}
        onSelectionChange={onSelectionWithSelectAll}
      >
        <FilterItem id="all">
          <Text slot="label">{allOptionsSelected}</Text>
        </FilterItem>
        <Separator />
        {options.map((option) => {
          return (
            <FilterItem id={option.value} key={option.value}>
              <Text slot="label">{option.label}</Text>
            </FilterItem>
          );
        })}
      </FilterMenu>
      <Separator />
      <DefaultJournalStatusModalBindingComponent
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        journalStatusOptions={options}
        onDefaultChange={onDefaultJournalStatusChange}
      />
    </FilterButtonWithMenu>
  );
};
