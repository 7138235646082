import React from "react";
import { Button } from "react-aria-components";
import "./IconButton.scss";
import ExpandIcon from "./images/expand.png";
import CollapseIcon from "./images/collapse.png";

export const IconButtonExpand = ({ onPress }: { onPress: () => void }) => {
  return (
    <Button className="react-aria-IconButton" onPress={onPress}>
      <img alt="expnd icon" src={ExpandIcon} width="20px" height="20px" />
    </Button>
  );
};

export const IconButtonCollapse = ({ onPress }: { onPress: () => void }) => {
  return (
    <Button className="react-aria-IconButton" onPress={onPress}>
      <img alt="collapses icon" src={CollapseIcon} width="20px" height="20px" />
    </Button>
  );
};
