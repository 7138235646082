export interface ProjectDetails {
  biid: string;
  code: string;
  name: string;
  valid_from: string;
  valid_to: string;
}
export async function fetchProjects() {
  return fetch("/api/projects")
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      return response.json() as Promise<ProjectDetails[]>;
    })
    .catch((error) => {
      throw error;
    });
}
