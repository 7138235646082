import {
  Dialog,
  MenuProps,
  MenuTriggerProps,
  Button,
  Label,
  MenuTrigger,
  Popover,
} from "react-aria-components";
import React, { ReactNode } from "react";
import "./FilterButtonWithMenu.scss";
import { ClearIconButton } from "../IconButton/ClearIconButton";
import { DropDownIcon } from "../IconButton/DropDownIcon";
import { allOptionsSelected } from "./GetFilterButtonText";

interface MyMenuButtonProps<T>
  extends MenuProps<T>,
    Omit<MenuTriggerProps, "children"> {
  children?: ReactNode;
  // Text will be shown in the button label
  buttonLabel: string;
  // Text will be appended to the button label
  buttonText: string;
  // Callback function to clear the selection
  onClearSelection: () => void;
  showClearIcon: boolean;
  onSelectionConfirm: () => void;
  // Optional state  to control the open and close state of Menu
  // special case : journal status filter
  // important note : both parentMenuStatus and setParentMenuStatus has to be passed together
  openState?: boolean;
  setOpenState?: React.Dispatch<React.SetStateAction<boolean>>;
}

// Filter button component with drop down menu that appear when the button is clicked.
// It receives the menu items via children props.
// ------------------------------------------------------
// | ButtonLabel( <":" + buttonText if available>)  [x] |
// ------------------------------------------------------
// ----------------------
// |     {children}     |
// ----------------------
// [x] - is clear button, Triggers onClearSelection
// Click outside of filter component to confirm selection
export const FilterButtonWithMenu = <T extends object>({
  children,
  onClearSelection,
  buttonLabel,
  buttonText,
  showClearIcon,
  onSelectionConfirm,
  openState,
  setOpenState,
  ...props
}: MyMenuButtonProps<T>) => {
  let [open, setOpen] = React.useState(false);
  // overriding menu status(open and close) from parent component, if it is provided
  if (openState !== undefined && setOpenState) {
    open = openState;
    setOpen = setOpenState;
  }
  const buttonTextOrDefault = buttonText || allOptionsSelected;

  const onClearSelectionHandler = (event: React.UIEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onClearSelection();
  };

  const handleOnOpenChange = (isOpened: boolean) => {
    setOpen(isOpened);

    // Confirm selection when outside click
    // Start from filter localMenuStatus state, isOpened is true
    // When click outside, filter switches to closed state. isOpened is false
    // if isOpened change from true to false, confirm selection
    if (!isOpened && onSelectionConfirm) onSelectionConfirm();
  };
  return (
    <MenuTrigger {...props} isOpen={open} onOpenChange={handleOnOpenChange}>
      <div>
        <Label>{buttonLabel}</Label>
        <Button
          {...(showClearIcon ? { "data-has-selection": true } : {})}
          className="react-aria-FilterButton"
        >
          <div className="button-text">{buttonTextOrDefault}</div>
          <div className="filter-controls">
            {showClearIcon && (
              <ClearIconButton onPointerDown={onClearSelectionHandler} />
            )}
            <DropDownIcon />
          </div>
        </Button>
      </div>
      <Popover offset={5} className="react-aria-FilterPopover">
        <Dialog
          aria-label="filterButton-dialog-label"
          className="react-aria-FilterDialog"
        >
          {children}
        </Dialog>
      </Popover>
    </MenuTrigger>
  );
};

export const DisabledFilterButton = ({
  buttonLabel,
}: {
  buttonLabel: string;
}) => {
  return (
    <MenuTrigger>
      <div>
        <Label>{buttonLabel}</Label>
        <Button isDisabled className="react-aria-FilterButton">
          <div className="button-text">{allOptionsSelected}</div>
          <div className="filter-controls">
            <DropDownIcon />
          </div>
        </Button>
      </div>
    </MenuTrigger>
  );
};
