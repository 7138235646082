export const AllDepartmentCommonValue = "without";
export const AllDepartmentCommonLabel = "全部門（共通）";
export interface Department {
  id: number;
  encrypted_id: string;
  isCommon: boolean; // This is for distinguish "***部門（共通）" department and "***部門（合計）" department
  name: string;
  name_for_search: string;
  identification_code: string;
  children: Department[];
}

export async function fetchDepartments() {
  return fetch("/api/depts?include_all_dept=true")
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      return response.json() as Promise<Department[]>;
    })
    .catch((error) => {
      throw error;
    });
}
