import {
  BUSINESS_PARTNERS,
  DEPARTMENTS,
  DISPLAY_SUB_ITEM_OPTIONS,
  END_DATE,
  ITEMS,
  JOURNAL_STATUSES,
  JOURNAL_TYPES,
  PROJECTS,
  START_DATE,
  SUB_ITEMS,
  TAX_FILTER,
} from "./constants";

import { Option } from "../Helpers/useFilterConfirmedState";
import { TermInfo } from "../MonthPicker/MonthPicker";
import { FilterSelection } from "../Helpers/FilterSelection";
import {
  TAX_EXCLUDE,
  TAX_EXCLUDE_INSIDE,
  TAX_INCLUDE,
} from "../TaxIncludeExcludeFilter/TaxIncludeExcludeFilter";

interface URLParams {
  Dates: string[] | undefined;
  JournalTypes: string[];
  JournalStatuses: string[];
  Departments: string[];
  BusinessPartners: string[];
  Items: string[];
  SubItems: string[];
  Projects: string[];
  TaxFilter: string;
  displaySubItemOption: string;
}
export const extractURLParams = (urlParams: URLSearchParams): URLParams => {
  const res: URLParams = {
    Dates: [],
    JournalTypes: [],
    JournalStatuses: [],
    Departments: [],
    BusinessPartners: [],
    Items: [],
    SubItems: [],
    Projects: [],
    TaxFilter: "",
    displaySubItemOption: "",
  };

  const startDate = urlParams.get(START_DATE);
  const endDate = urlParams.get(END_DATE);
  if (startDate && endDate) {
    res.Dates = [startDate, endDate];
  }

  const journalTypes = urlParams.get(JOURNAL_TYPES);
  if (journalTypes) {
    res.JournalTypes = journalTypes.split(",");
  }

  const journalStatuses = urlParams.get(JOURNAL_STATUSES);
  if (journalStatuses) {
    res.JournalStatuses = journalStatuses.split(",");
  }

  const departments = urlParams.get(DEPARTMENTS);
  if (departments) {
    res.Departments = departments.split(",");
  }

  const businessPartners = urlParams.get(BUSINESS_PARTNERS);
  if (businessPartners) {
    res.BusinessPartners = businessPartners.split(",");
  }

  const items = urlParams.get(ITEMS);
  if (items) {
    res.Items = items.split(",");
  }

  const subItems = urlParams.get(SUB_ITEMS);
  if (subItems) {
    res.SubItems = subItems.split(",");
  }

  const projects = urlParams.get(PROJECTS);
  if (projects) {
    res.Projects = projects.split(",");
  }
  const taxFilter = urlParams.get(TAX_FILTER);
  if (taxFilter) {
    res.TaxFilter = taxFilter;
  }

  const displaySubItemOptions = urlParams.get(DISPLAY_SUB_ITEM_OPTIONS);
  if (displaySubItemOptions) {
    res.displaySubItemOption = displaySubItemOptions;
  }

  return res;
};

export function validateInitialState(
  options: Option[],
  initialState: string[] | undefined
) {
  if (initialState === undefined) {
    return [];
  }
  if (initialState[0] === "all") {
    return "all" as FilterSelection;
  }
  return options
    .filter((option) => initialState.includes(option.value))
    .map((department) => department.value) as FilterSelection;
}

export function validateInitialMonthState(
  termInfo: TermInfo,
  dates: string[] | undefined
) {
  if (termInfo === undefined || dates === undefined || dates.length !== 2) {
    return [];
  }
  const startDate = dates[0];
  const endDate = dates[1];
  const matchingMonth = Object.keys(termInfo.monthsInfo).find((month) => {
    const { startDate: monthStartDate, endDate: monthEndDate } =
      termInfo.monthsInfo[month];
    return startDate === monthStartDate || endDate === monthEndDate;
  });

  return matchingMonth ? [startDate, endDate] : [];
}

export function validateInitialDisplayOption(displaySubItemOption: string) {
  if (displaySubItemOption === "false") {
    return displaySubItemOption;
  }
  return "true";
}

export function validateInitialTaxOption(
  taxOption: string,
  includeTaxId?: string
) {
  if (!includeTaxId) {
    // If includeTaxId is not available, we cannot determine if the tax is included or excluded,
    // so we choose to display it as excluded(default value)
    return TAX_EXCLUDE;
  }
  // TAX_INCLUDE option works  when user's tax setting(termDetails.include_tax_id) = TAX_EXCLUDE_INSIDE
  if (taxOption === TAX_INCLUDE && includeTaxId === TAX_EXCLUDE_INSIDE) {
    return TAX_INCLUDE;
  }
  return TAX_EXCLUDE;
}
