import React, { useState } from "react";
import "./MonthPicker.css";

export interface MonthsInfo {
  [month: string]: MonthDate;
}

interface MonthDate {
  startDate: string;
  endDate: string;
}

export interface TermInfo {
  startYear: string;
  endYear: string;
  startYearTermMonths: string[];
  endYearTermMonths: string[];
  monthsInfo: MonthsInfo;
  endDateOfTheTerm: string;
}

interface GridBoxProps {
  termMonths: string[];
  handleMonthClick: (month: string) => void;
  selectedMonths: string[];
  currentHoveredMonth: string | null;
  setCurrentHoveredMonth: (month: string | null) => void;
  termAllMonths: string[];
}

// return the months between 2 selected months
function getMonthsBetween(
  termAllMonths: string[],
  firstMonth: string,
  lastMonth: string
): string[] {
  const startMonthIndex = termAllMonths.indexOf(firstMonth);
  const endMonthIndex = termAllMonths.indexOf(lastMonth);
  return termAllMonths.slice(startMonthIndex, endMonthIndex);
}
const GridBox = ({
  termMonths,
  handleMonthClick,
  selectedMonths,
  currentHoveredMonth,
  setCurrentHoveredMonth,
  termAllMonths,
}: GridBoxProps) => {
  const monthInNumber: string[] = Array.from({ length: 12 }, (_, i) =>
    (i + 1).toString()
  );

  return (
    <div className="grid-box">
      {monthInNumber.map((number) => {
        const isPickable = termMonths.includes(number);
        const buttonClassName = isPickable
          ? "pickable-month"
          : "non-pickable-month";
        const selectedMonthClassName =
          isPickable && selectedMonths.includes(number) ? "selected-month" : "";
        const currentHoveredButtonClassName =
          isPickable &&
          currentHoveredMonth === number &&
          !selectedMonths.includes(number)
            ? "current-hovered"
            : "";

        let betweenMonths: string[] = [];
        const lastMonth = selectedMonths[1] || currentHoveredMonth;
        if (selectedMonths[0] && lastMonth) {
          betweenMonths = getMonthsBetween(
            termAllMonths,
            selectedMonths[0],
            lastMonth
          );
        }
        const buttonBetweenClassName =
          isPickable &&
          betweenMonths?.includes(number) &&
          !selectedMonths.includes(number)
            ? "range-between-button"
            : "";

        return (
          <button
            key={number}
            type="submit"
            id={`month-${number}`}
            aria-label={buttonClassName}
            className={`grid-item ${buttonClassName} ${selectedMonthClassName} ${currentHoveredButtonClassName} ${buttonBetweenClassName}`}
            data-testid={`${buttonClassName}-${number}`}
            onClick={() => handleMonthClick(number)}
            onMouseEnter={() => setCurrentHoveredMonth(number)}
            onMouseLeave={() => setCurrentHoveredMonth(null)}
          >
            {number}
          </button>
        );
      })}
    </div>
  );
};

export function getMonthFromDate(date: string): string {
  return (new Date(date).getMonth() + 1).toString();
}

export function convertFromEndDateToStartDate(
  endDate: string,
  monthsInfo: MonthsInfo
): string {
  // monthsInfo do not have iterator for alternative nay, so we use Object.entries
  // eslint-disable-next-line no-restricted-syntax
  for (const termMonth of Object.values(monthsInfo)) {
    if (endDate >= termMonth.startDate && endDate <= termMonth.endDate) {
      return termMonth.startDate;
    }
  }
  return "";
}

export function convertFromStartDateToEndDate(
  startDate: string,
  monthsInfo: MonthsInfo
): string {
  // monthsInfo do not have iterator for alternative nay, so we use Object.entries
  // eslint-disable-next-line no-restricted-syntax
  for (const termMonth of Object.values(monthsInfo)) {
    if (startDate >= termMonth.startDate && startDate <= termMonth.endDate) {
      return termMonth.endDate;
    }
  }
  return "";
}

interface MonthPickerProps {
  termInfo?: TermInfo | null;
  handleMonthClick: (month: string) => void;
  selectedDate: string[];
}
const MonthPicker = ({
  termInfo,
  handleMonthClick,
  selectedDate,
}: MonthPickerProps) => {
  const [currentHoveredMonth, setCurrentHoveredMonth] = useState<string | null>(
    null
  );

  if (
    !termInfo ||
    !termInfo.monthsInfo ||
    Object.keys(termInfo.monthsInfo).length === 0
  ) {
    return <p>会計期間情報が取得できません</p>;
  }

  const selectedMonth: string[] = [];
  if (selectedDate.length === 1) {
    selectedMonth[0] = getMonthFromDate(selectedDate[0]);
  }
  if (selectedDate.length === 2) {
    selectedMonth[0] = getMonthFromDate(selectedDate[0]);
    // for second picked option, we should extract month number from start date for irregular term case like 2025/06/03~2026/06/02
    const startDate = convertFromEndDateToStartDate(
      selectedDate[1],
      termInfo.monthsInfo
    );
    selectedMonth[1] = getMonthFromDate(startDate);
  }

  let nextYear = termInfo.endYear;
  if (termInfo.startYear === termInfo.endYear) {
    nextYear = (parseInt(termInfo.endYear, 10) + 1).toString();
  }

  const termAllMonths = termInfo.startYearTermMonths.concat(
    termInfo.endYearTermMonths
  );

  return (
    <div className="month-picker-container">
      <div className="month-grid-container">
        <div>
          <p className="term-year">{`${termInfo.startYear}年`}</p>
          <GridBox
            termMonths={termInfo.startYearTermMonths}
            handleMonthClick={handleMonthClick}
            selectedMonths={selectedMonth}
            currentHoveredMonth={currentHoveredMonth}
            setCurrentHoveredMonth={setCurrentHoveredMonth}
            termAllMonths={termAllMonths}
          />
        </div>
        <div>
          <p className="term-year">{`${nextYear}年`}</p>
          <GridBox
            termMonths={termInfo.endYearTermMonths}
            handleMonthClick={handleMonthClick}
            selectedMonths={selectedMonth}
            currentHoveredMonth={currentHoveredMonth}
            setCurrentHoveredMonth={setCurrentHoveredMonth}
            termAllMonths={termAllMonths}
          />
        </div>
      </div>
    </div>
  );
};

export default MonthPicker;
