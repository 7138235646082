import { TAX_INCLUDE } from "../TaxIncludeExcludeFilter/TaxIncludeExcludeFilter";
import {
  BUSINESS_PARTNERS,
  DEPARTMENTS,
  DISPLAY_SUB_ITEM_OPTIONS,
  END_DATE,
  ITEMS,
  JOURNAL_STATUSES,
  JOURNAL_TYPES,
  MAX_URL_LENGTH,
  PROJECTS,
  START_DATE,
  SUB_ITEMS,
  TAX_FILTER,
} from "./constants";
import { FilterSelection } from "../Helpers/FilterSelection";

// if URL is too long, we get en error from ca-mid-web
// to prevent it we check if adding parameter will make URL too long
function urlIsTooLong(url: string) {
  return url.length > MAX_URL_LENGTH;
}

function joinFilterSelection(selection: FilterSelection | undefined): string {
  if (selection === undefined) {
    return "";
  }
  if (selection === "all") {
    return "all";
  }
  return selection.join(",");
}

const prepareURLParamsFromFilter = (filtersStates: {
  JournalTypes?: FilterSelection;
  JournalStatuses?: FilterSelection;
  Departments?: FilterSelection;
  BusinessPartners?: FilterSelection;
  Items?: FilterSelection;
  SubItems?: FilterSelection;
  Projects?: FilterSelection;
  TaxFilter?: string;
  DisplaySubItemOption?: string;
  Dates?: string[] | undefined;
}) => {
  const urlParams = [];

  const dates = filtersStates.Dates || [];
  urlParams.push([START_DATE, dates[0] || ""], [END_DATE, dates[1] || ""]);

  urlParams.push([
    JOURNAL_TYPES,
    joinFilterSelection(filtersStates.JournalTypes),
  ]);
  urlParams.push([
    JOURNAL_STATUSES,
    joinFilterSelection(filtersStates.JournalStatuses),
  ]);

  urlParams.push([DEPARTMENTS, joinFilterSelection(filtersStates.Departments)]);

  urlParams.push([
    BUSINESS_PARTNERS,
    joinFilterSelection(filtersStates.BusinessPartners),
  ]);

  urlParams.push([ITEMS, joinFilterSelection(filtersStates.Items)]);

  urlParams.push([PROJECTS, joinFilterSelection(filtersStates.Projects)]);

  urlParams.push([SUB_ITEMS, joinFilterSelection(filtersStates.SubItems)]);

  // we will handle empty url parameter for tax_filter as tax_excluded
  // this will allow us to have empty url for empty filters states
  urlParams.push([
    TAX_FILTER,
    filtersStates.TaxFilter === TAX_INCLUDE ? filtersStates.TaxFilter : "",
  ]);

  urlParams.push([
    DISPLAY_SUB_ITEM_OPTIONS,
    filtersStates.DisplaySubItemOption === "false"
      ? filtersStates.DisplaySubItemOption
      : "",
  ]);
  return urlParams;
};

export function updateURLWithFiltersStates(
  currentUrlParams: URLSearchParams,
  filtersStates: {
    JournalTypes?: FilterSelection;
    JournalStatuses?: FilterSelection;
    Departments?: FilterSelection;
    BusinessPartners?: FilterSelection;
    Items?: FilterSelection;
    SubItems?: FilterSelection;
    Projects?: FilterSelection;
    TaxFilter?: string;
    DisplaySubItemOption?: string;
    Dates?: string[] | undefined;
  }
) {
  const filterUrlParams = prepareURLParamsFromFilter(filtersStates);

  filterUrlParams.forEach((urlParam) => {
    const [paramName, paramValue] = urlParam;
    if (paramValue === "") {
      currentUrlParams.delete(paramName);
    } else if (!urlIsTooLong(currentUrlParams.toString() + paramValue)) {
      currentUrlParams.set(paramName, paramValue);
    }
  });
  const newUrl = currentUrlParams.toString();
  window.history.pushState({}, "", newUrl ? `?${newUrl}` : "/");
}
