import React, { memo, useEffect, useMemo, useRef } from "react";
import "./ReportView.css";
import ScrollContainer, { ScrollEvent } from "react-indiana-drag-scroll";
import RenderIfVisible from "react-render-if-visible";
import { Project } from "../../__generated__/graphql";
import ProjectTable, { getNumberOfMonths } from "./ProjectTable";
import ProjectSummary from "./ProjectSummary";
import { ExpandAllButton } from "./ExpandAllButton/ExpandAllButton";
import { ProjectReportAction, State } from "../../useProjectReportReducer";

const ProjectView = memo(
  ({
    project,
    drillDownUrlParameter,
    isExpandedState,
    onExpandCollapse,
  }: {
    project: Project;
    drillDownUrlParameter: string | null;
    isExpandedState: boolean;
    onExpandCollapse: (biid: string, isExpandedCurrentState: boolean) => void;
  }) => {
    const onExpandCollapseUpdate = () => {
      onExpandCollapse?.(project.biid, !isExpandedState);
    };

    const headersRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLElement>(null);

    // Sync scroll areas of the report and the sticky header
    const handleScroll = (event: ScrollEvent) => {
      if (headersRef.current && contentRef.current)
        headersRef.current.scrollLeft = contentRef.current.scrollLeft;
    };

    // Basically We sync the report and header positions bay handleScroll function by onScroll event
    // However, onScroll of the react-indiana-drag-scroll may not work properly on some environments
    // Alternative way, we set interval timer for the case
    useEffect(() => {
      const syncScroll = setInterval(() => {
        if (headersRef.current && contentRef.current)
          headersRef.current.scrollLeft = contentRef.current.scrollLeft;
      }, 10);
      return () => clearInterval(syncScroll);
    }, []);

    // We show sticky headers bellow sticky summary, but summary has variable height,
    // so we need to set top property of sticky headers to summary height
    const summaryRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (headersRef.current && summaryRef.current)
        headersRef.current.style.setProperty(
          "--top",
          `${summaryRef.current.offsetHeight - 1}px`
        );
    }, [isExpandedState]);

    // 32 is a default height for row in ProjectTable
    const defaultHeightForPlaceholder = project.projectViews.length * 32;

    return (
      <>
        {/* simple drop down header */}
        <ProjectSummary
          projectData={project}
          key={project.name}
          onClick={onExpandCollapseUpdate}
          isExpanded={isExpandedState}
          ref={summaryRef}
        />
        {isExpandedState ? (
          <>
            {/* Why table headers outside of Project Table?
          the main reason is to make sticky headers work: position: sticky; top: 0;
          main obstacle for it inside ProjectTable is that we have to make the table scrollable: overflow-y: scroll;
          it prevents sticky headers from working.

          If we put sticky headers outside overflow-y: scroll; it works. But we get another problem:
          Now sticky headers and table body have different scrolls areas.
          To make it works we have to sync scroll areas with onScroll={handleScroll} function.
          */}
            <div
              role="menuitem"
              className="table-columns-headers"
              ref={headersRef}
              style={
                {
                  "--number-of-months": getNumberOfMonths(
                    project.reportTableHeaders
                  ),
                } as React.CSSProperties
              }
            >
              <div className="column-header-row">
                {project.reportTableHeaders.map((header, key) => {
                  return (
                    <div
                      className="column-header"
                      key={header + key.toString()}
                    >
                      {header}
                    </div>
                  );
                })}
              </div>
            </div>
            <ScrollContainer
              className="dropdown-content"
              onScroll={handleScroll}
              onEndScroll={handleScroll}
              innerRef={contentRef}
              hideScrollbars={false}
            >
              <RenderIfVisible
                defaultHeight={defaultHeightForPlaceholder}
                stayRendered
                placeholderElementClass="render-if-visible-placeholder"
              >
                <ProjectTable
                  projectViews={project.projectViews}
                  reportTableHeaders={project.reportTableHeaders}
                  projectBiid={project.biid}
                  drillDownUrlParameter={drillDownUrlParameter}
                />
              </RenderIfVisible>
            </ScrollContainer>
          </>
        ) : null}
      </>
    );
  }
);

const useProjectsIsExpandedState = (
  ProjectsBIIDs: string[],
  expandedProjects: Set<string>,
  dispatch: React.Dispatch<ProjectReportAction>
) => {
  const updateProjectState = useMemo(() => {
    return (biid: string, setIsExpanded: boolean) => {
      const newSet = new Set(expandedProjects);

      // Use setIsExpanded as a flag to add or remove from the set
      if (setIsExpanded) {
        newSet.add(biid);
      } else {
        newSet.delete(biid);
      }

      dispatch({
        type: "UPDATE_EXPANDED_PROJECTS",
        payload: {
          expandedProjects: newSet,
        },
      });
    };
  }, [dispatch, expandedProjects]);

  const expandAllProjects = () => {
    dispatch({
      type: "UPDATE_EXPANDED_PROJECTS",
      payload: {
        expandedProjects: new Set(ProjectsBIIDs),
      },
    });
  };

  const collapseAllProjects = () => {
    dispatch({
      type: "UPDATE_EXPANDED_PROJECTS",
      payload: {
        expandedProjects: new Set(),
      },
    });
  };

  return {
    updateProjectState,
    expandAllProjects,
    collapseAllProjects,
  };
};

interface ReportViewProps {
  projects: Project[] | undefined;
  state: State;
  dispatch: React.Dispatch<ProjectReportAction>;
  queryParams: string | null;
}
const ReportView = ({
  projects,
  state,
  dispatch,
  queryParams,
}: ReportViewProps) => {
  const { expandedProjects } = state;
  // We want to control expanded state of projects from outside ProjectView, we use this state for it
  const { updateProjectState, expandAllProjects, collapseAllProjects } =
    useProjectsIsExpandedState(
      (projects ?? []).map((project) => project.biid),
      expandedProjects,
      dispatch
    );

  // Update the expansion status of projects when the list of fetched projects changes due to
  // filter conditions. We update the expansion status of projects only if they are present
  // in the current list of fetched projects and were previously expanded.
  useEffect(() => {
    const projectBIIDs = (projects ?? []).map((project) => project.biid);
    dispatch({
      type: "UPDATE_EXPANDED_PROJECTS",
      payload: {
        expandedProjects: new Set(
          [...projectBIIDs].filter((projectBIID) =>
            expandedProjects.has(projectBIID)
          )
        ),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, dispatch]);

  return (
    <>
      {/* report-view is a container for table */}
      <div className="report-view-container report-view">
        {/* definition for headers */}
        <div className="summary-wrapper">
          <div className="summary-header project-header">プロジェクト名</div>
          <div className="summary-header">売上高</div>
          <div className="summary-header">売上原価</div>
          <div className="summary-header">売上総利益</div>
          <div className="summary-header summary-rate">売上総利益率</div>
          <div className="summary-header">営業利益</div>
          <div className="summary-header summary-rate">営業利益率</div>
          <div className="options-button">
            <ExpandAllButton
              allProjectsExpanded={expandedProjects.size === projects?.length}
              onExpandAll={expandAllProjects}
              onCollapseAll={collapseAllProjects}
            />
          </div>
        </div>
        {projects?.map((project) => {
          /* report-view is a container for table */
          return (
            <div className="report-view" key={project.name}>
              <ProjectView
                key={project.name}
                project={project}
                drillDownUrlParameter={queryParams}
                isExpandedState={expandedProjects.has(project.biid)}
                onExpandCollapse={updateProjectState}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ReportView;
