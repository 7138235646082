import { useMemo } from "react";
import {
  AllDepartmentCommonLabel,
  AllDepartmentCommonValue,
  Department,
} from "../../../api/getDepartment";

export const parentDepartmentSuffix = "_all";
export const parentOnlyDepartmentSuffix = "_only";

export const useDepartmentOptions = (data: Department[]) => {
  return useMemo(() => {
    if (!data) {
      return [];
    }
    return data.map((dep) => {
      let childOptions = dep.children.map((childDep) => {
        return {
          value: childDep.id.toString(),
          label: `${childDep.name}`,
          name_for_search: childDep.name_for_search,
          identification_code: childDep.identification_code,
          encrypted_id: childDep.encrypted_id,
          is_common: false,
        };
      });

      // If department doesn't have children we will use parent department check box and parent id
      // If department has children we will use parent department check box for select/deselect all in this department
      // and will add child department with suffix <_without children> for filtering only journals in parent department
      let parentCheckBoxValue = dep.id.toString();
      let parentCheckBoxLabel = dep.name.toString();
      if (childOptions.length > 0) {
        childOptions = [
          {
            value: parentCheckBoxValue,
            label: `${dep.name}（共通）`,
            identification_code: "",
            name_for_search: "",
            encrypted_id: dep.encrypted_id,
            is_common: true,
          },
          ...childOptions,
        ];
        // make parent department utility option for select/deselect all
        parentCheckBoxValue = `${parentCheckBoxValue}${parentDepartmentSuffix}`;
      }

      // department API return the "全部門" option, but we want to show "全部門（共通）" in UI
      // If we specify no department"全部門（共通）" in the filter, we should set "without" value for the backend
      let isCommonValue = false;
      if (parentCheckBoxLabel === "全部門") {
        parentCheckBoxLabel = AllDepartmentCommonLabel;
        parentCheckBoxValue = AllDepartmentCommonValue;
        isCommonValue = true;
      }

      return {
        value: parentCheckBoxValue,
        label: parentCheckBoxLabel,
        name_for_search: dep.name_for_search,
        identification_code: dep.identification_code,
        encrypted_id: dep.encrypted_id,
        is_common: isCommonValue,
        children: childOptions,
      };
    });
  }, [data]);
};
