import React from "react";
import { Separator, Text } from "react-aria-components";
import {
  allOptionsSelected,
  GetFilterButtonCounter,
} from "../../CommonComponents/FilterButtonWithMenu/GetFilterButtonText";
import { useSearch } from "../../CommonComponents/SearchField/useSearch";
import { useSelectedWithSelectAllLogic } from "../../CommonComponents/FilterButtonWithMenu/useSelectedWithSelectAllLogic";
import { FilterButtonWithMenu } from "../../CommonComponents/FilterButtonWithMenu/FilterButtonWithMenu";
import SearchField from "../../CommonComponents/SearchField/SearchField";
import {
  FilterItem,
  FilterMenu,
} from "../../CommonComponents/FilterButtonWithMenu/FilterMenu";
import { NoOptionsText } from "../Helpers/NoOptionsText";
import { hasSelection } from "../Helpers/useFilterConfirmedState";
import { FilterSelection } from "../Helpers/FilterSelection";
import { useInfiniteScroll } from "../../CommonComponents/FilterButtonWithMenu/useInfiniteScroll";

export interface BusinessPartnerOption {
  value: string;
  label: string;
  name_for_search: string;
  identification_code: string;
  encrypted_id: string;
}

export const BusinessPartnerFilter = ({
  options,
  confirmedSelection,
  setConfirmedSelection,
}: {
  options: BusinessPartnerOption[];
  confirmedSelection: FilterSelection;
  setConfirmedSelection: (state: Set<React.Key>) => void;
}) => {
  const filterStateText = GetFilterButtonCounter(confirmedSelection);
  const [filteredOptions, onSearch, clearSearch, searchHasFocus, setFocus] =
    useSearch(options);
  const [selected, onSelectionWithSelectAll] = useSelectedWithSelectAllLogic(
    filteredOptions.map((option) => option.value),
    confirmedSelection
  );

  const onSelectionConfirm = () => {
    clearSearch();
    setConfirmedSelection(selected);
  };

  const { visibleOptions, onHandleScroll } =
    useInfiniteScroll<BusinessPartnerOption>(filteredOptions);

  return (
    <FilterButtonWithMenu
      buttonLabel="取引先"
      buttonText={filterStateText}
      showClearIcon={hasSelection(confirmedSelection)}
      onClearSelection={() => {
        setConfirmedSelection(new Set());
        onSelectionWithSelectAll(new Set());
      }}
      onSelectionConfirm={onSelectionConfirm}
    >
      <SearchField
        placeholderText="取引先名、コード、検索キー"
        onChange={onSearch}
        setFocus={setFocus}
      />
      {filteredOptions.length > 0 ? (
        <FilterMenu
          // Important! This is preventing from losing focus on search field when results reappear
          autoFocus={!searchHasFocus}
          selectionMode="multiple"
          selectedKeys={selected}
          onSelectionChange={onSelectionWithSelectAll}
          onScroll={onHandleScroll}
        >
          <FilterItem id="all" removeBackgroundForSelected>
            <Text slot="label">{allOptionsSelected}</Text>
          </FilterItem>
          <Separator />
          {visibleOptions.map((option) => {
            return (
              <FilterItem id={option.value} key={option.value}>
                <Text slot="label">{option.label}</Text>
                <Text slot="description">{option.identification_code}</Text>
              </FilterItem>
            );
          })}
        </FilterMenu>
      ) : (
        <NoOptionsText />
      )}
    </FilterButtonWithMenu>
  );
};
