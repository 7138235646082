import React, { createContext, useState, useMemo, useCallback } from "react";
import "./DisplayMessageProvider.css";
import ErrorIcon from "./images/error-icon.png";
import CrossIcon from "./images/cross-icon.png";

export const DisplayMessageContext = createContext<{
  addMessage: (message: Message) => void;
}>({ addMessage: () => {} });

export enum MessageType {
  Success = "SUCCESS",
  Error = "ERROR",
}

interface Message {
  text: string;
  type: MessageType;
  // createdAt are not used currently
  createdAt: Date;
}

export const NewMessage = (text: string, type: MessageType): Message => {
  return {
    text,
    type,
    createdAt: new Date(),
  };
};

export const Alert = ({
  message,
  onClose,
}: {
  message: Message;
  onClose: (message: Message) => void;
}) => {
  function handleClickClose() {
    onClose(message);
  }

  return (
    <div className="message">
      <img alt="message icon" className="message-icon" src={ErrorIcon} />
      <div className="message-text">{message.text}</div>
      <button
        type="button"
        className="message-close"
        onClick={handleClickClose}
      >
        <img
          alt="message icon"
          className="message-close-icon"
          src={CrossIcon}
        />
      </button>
    </div>
  );
};

export const DisplayMessageProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [messages, setMessages] = useState<Message[]>([]);

  const handleClose = useCallback((closedMessage: Message) => {
    // we must use currentMessages from setMessages function to access latest version.
    setMessages((currentMessages) =>
      currentMessages.filter((message) => message !== closedMessage)
    );
  }, []);

  const value = useMemo(() => {
    return {
      addMessage: (messageToShow: Message) => {
        setMessages([...messages, messageToShow]);
        // Close messages after 3 seconds.
        setTimeout(() => {
          handleClose(messageToShow);
        }, 3000);
      },
    };
  }, [messages, handleClose]);

  return (
    <DisplayMessageContext.Provider value={value}>
      {children}
      <div className="messages-board">
        {messages.map((message) => {
          return (
            <Alert
              message={message}
              onClose={handleClose}
              key={message.createdAt.getTime()}
            />
          );
        })}
      </div>
    </DisplayMessageContext.Provider>
  );
};
