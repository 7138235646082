import type { MenuProps, MenuTriggerProps } from "react-aria-components";
import {
  Header,
  Menu,
  MenuItem,
  MenuItemProps,
  Section,
} from "react-aria-components";
import React from "react";
import "./FilterMenu.scss";

import { FilterCheckbox } from "./FilterCheckbox";

export const FilterSection = ({
  children,
  category,
}: MenuItemProps & {
  category: string;
}) => {
  return (
    <Section>
      <>
        <Header>{category}</Header>
        {children}
      </>
    </Section>
  );
};

export const FilterItem = ({
  children,
  removeBackgroundForSelected,
  indentation,
  ...props
}: MenuItemProps & {
  removeBackgroundForSelected?: boolean;
  indentation?: number;
}) => {
  return (
    <MenuItem
      // if flag is not specified we add tag, because common case needs background for selected item
      data-with-background-for-selected={!removeBackgroundForSelected}
      style={
        {
          "--item-indentation": indentation,
        } as React.CSSProperties
      }
      {...props}
    >
      {({ isSelected }) => (
        <>
          <FilterCheckbox isSelected={isSelected as boolean} />
          {children}
        </>
      )}
    </MenuItem>
  );
};

interface FilterMenuProps<T>
  extends MenuProps<T>,
    Omit<MenuTriggerProps, "children"> {}

export const FilterMenu = <T extends object>({
  children,
  ...props
}: FilterMenuProps<T>) => {
  return (
    <Menu
      disabledKeys={["no-item"]}
      className="react-aria-FilterMenu"
      shouldFocusWrap={false}
      {...props}
    >
      {children}
    </Menu>
  );
};
