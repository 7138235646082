export interface ItemWithCategory {
  grouped_name: string;
  items: Item[];
}
export interface Item {
  id: number;
  name: string;
  name_for_search: string;
  is_active: boolean;
  code: string;
  excise_id: number;
}
export async function fetchItemsWithCategory() {
  return fetch("/api/items/grouped_items")
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      return response.json() as Promise<ItemWithCategory[]>;
    })
    .catch((error) => {
      throw error;
    });
}
