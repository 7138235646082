import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import { QueryClientProvider } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import App from "./App";
import { graphQLClient } from "./queryClient/apolloClient";
import { DisplayMessageProvider } from "./components/DisplayAlert/DisplayMessageProvider";
import { MasterDataContextProvider } from "./FilterContext";
import { ErrorPage, logError } from "./components/ErrorPage/ErrorPage";
import { reactQueryClient } from "./queryClient/reactQueryClient";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ErrorBoundary fallbackRender={ErrorPage} onError={logError}>
    <ApolloProvider client={graphQLClient}>
      <QueryClientProvider client={reactQueryClient}>
        <DisplayMessageProvider>
          <MasterDataContextProvider>
            <App />
          </MasterDataContextProvider>
        </DisplayMessageProvider>
      </QueryClientProvider>
    </ApolloProvider>
  </ErrorBoundary>
);
