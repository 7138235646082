import { MenuItem, Separator, Text } from "react-aria-components";
import React, { useEffect, useMemo } from "react";
import {
  allOptionsSelected,
  GetFilterButtonCounter,
} from "../../CommonComponents/FilterButtonWithMenu/GetFilterButtonText";
import { useSearch } from "../../CommonComponents/SearchField/useSearch";
import { useSelectedWithSelectAllLogic } from "../../CommonComponents/FilterButtonWithMenu/useSelectedWithSelectAllLogic";
import {
  FilterButtonWithMenu,
  DisabledFilterButton,
} from "../../CommonComponents/FilterButtonWithMenu/FilterButtonWithMenu";
import SearchField from "../../CommonComponents/SearchField/SearchField";
import {
  FilterItem,
  FilterMenu,
  FilterSection,
} from "../../CommonComponents/FilterButtonWithMenu/FilterMenu";
import { NoOptionsText } from "../Helpers/NoOptionsText";
import { ItemOption } from "./ItemFilter";
import {
  hasSelection,
  listToSetSelection,
} from "../Helpers/useFilterConfirmedState";
import { FilterSelection } from "../Helpers/FilterSelection";
import { useInfiniteScroll } from "../../CommonComponents/FilterButtonWithMenu/useInfiniteScroll";

// Extract ItemOptions from SelectedItems
const getSelectedItemOptions = (
  selectedItems: FilterSelection,
  options: ItemOption[]
) => {
  if (selectedItems === "all") {
    return options;
  }
  const selectedItemsSet = new Set(selectedItems);
  return options.filter((option) => selectedItemsSet.has(option.value));
};

// Deconstructed ItemOption to SubItemOption
export const getAllSubItemOptionsFlat = (options: ItemOption[]) => {
  return options
    .map((ItemOptions) => {
      return ItemOptions.sub_items;
    })
    .flat();
};

// Reconstructed filteredOptions to ItemOption
const getFilteredItemOptions = (
  ItemOptions: ItemOption[],
  filteredOptions: ItemOption[]
) => {
  const result: ItemOption[] = [];
  ItemOptions.forEach((item) => {
    const currentItem: ItemOption = {
      value: item.value,
      label: item.label,
      name_for_search: item.name_for_search,
      identification_code: item.identification_code,
      sub_items: [],
    };
    const filteredOptionsSet = new Set(filteredOptions);
    const currentOptions: ItemOption[] = item.sub_items.filter((subItem) => {
      return filteredOptionsSet.has(subItem);
    });
    if (currentOptions.length > 0) {
      currentItem.sub_items = currentOptions;
    }
    result.push(currentItem);
  });

  return result;
};

export const SubItemFilter = ({
  options,
  confirmedSelection,
  setConfirmedSelection,
  SelectedItems,
}: {
  options: ItemOption[];
  confirmedSelection: FilterSelection;
  setConfirmedSelection: (state: Set<React.Key>) => void;
  SelectedItems: FilterSelection;
}) => {
  const subItemWithItem = useMemo(() => {
    return getSelectedItemOptions(SelectedItems, options);
  }, [SelectedItems, options]);
  const allOptionsFlat = useMemo(() => {
    return getAllSubItemOptionsFlat(subItemWithItem);
  }, [subItemWithItem]);

  const filterStateText = GetFilterButtonCounter(confirmedSelection);
  const [filteredOptions, onSearch, clearSearch, searchHasFocus, setFocus] =
    useSearch(allOptionsFlat);
  const [selected, onSelectionWithSelectAll] = useSelectedWithSelectAllLogic(
    filteredOptions.map((option) => option.value),
    confirmedSelection
  );

  useEffect(() => {
    const itemList = listToSetSelection(SelectedItems);
    // If user delete selectedItems including selected subItems, we need to remove them from confirmedSelection
    if (itemList !== "all" && confirmedSelection.length > 0) {
      const newConfirmedSelection = confirmedSelection.toString().split(",");
      const initialSubItem: string[] = [];
      newConfirmedSelection.forEach((selection) => {
        const parentItem = selection.split("_")[0];
        if (SelectedItems.includes(parentItem)) {
          initialSubItem.push(selection);
        }
      });

      setConfirmedSelection(new Set(initialSubItem));
      onSelectionWithSelectAll(new Set(initialSubItem));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SelectedItems]);

  const filteredSubItemWithItemOptions = useMemo(
    () => getFilteredItemOptions(subItemWithItem, filteredOptions),
    [subItemWithItem, filteredOptions]
  );

  const onSelectionConfirm = () => {
    clearSearch();
    setConfirmedSelection(selected);
  };

  const { visibleOptions, onHandleScroll } = useInfiniteScroll<ItemOption>(
    filteredSubItemWithItemOptions
  );

  if (hasSelection(SelectedItems)) {
    return (
      <FilterButtonWithMenu
        buttonLabel="補助科目"
        buttonText={filterStateText}
        showClearIcon={hasSelection(confirmedSelection)}
        onClearSelection={() => {
          setConfirmedSelection(new Set());
          onSelectionWithSelectAll(new Set());
        }}
        onSelectionConfirm={onSelectionConfirm}
      >
        <SearchField
          placeholderText="勘定科目名、コード、検索キー"
          onChange={onSearch}
          setFocus={setFocus}
        />
        {getAllSubItemOptionsFlat(filteredSubItemWithItemOptions).length > 0 ? (
          <FilterMenu
            // Important! This is preventing from losing focus on search field when results reappear
            autoFocus={!searchHasFocus}
            selectionMode="multiple"
            selectedKeys={selected}
            onSelectionChange={onSelectionWithSelectAll}
            onScroll={onHandleScroll}
          >
            <FilterItem id="all" key="all" removeBackgroundForSelected>
              <Text slot="label">{allOptionsSelected}</Text>
            </FilterItem>
            <Separator />
            {visibleOptions.map((filteredItem, key) => {
              return (
                <React.Fragment key={filteredItem.value + key.toString()}>
                  {filteredItem.sub_items.length > 0 ? (
                    <FilterSection category={filteredItem.label}>
                      {filteredItem.sub_items.map((subItem) => {
                        return (
                          <FilterItem id={subItem.value} key={subItem.value}>
                            <Text slot="label">{subItem.label}</Text>
                            <Text slot="description">
                              {subItem.identification_code}
                            </Text>
                          </FilterItem>
                        );
                      })}
                    </FilterSection>
                  ) : (
                    // Show "該当科目なし(The item have no sub item)" if filteredItem.sub_items is empty
                    <FilterSection category={filteredItem.label}>
                      <MenuItem id="no-item" key="no-item">
                        <Text slot="label">該当科目なし</Text>
                      </MenuItem>
                    </FilterSection>
                  )}
                  <Separator />
                </React.Fragment>
              );
            })}
          </FilterMenu>
        ) : (
          <NoOptionsText />
        )}
      </FilterButtonWithMenu>
    );
  }
  // Show Disabled SubItemFilter when selectedItem is not selected
  return <DisabledFilterButton buttonLabel="補助科目" />;
};
