import { useMemo } from "react";
import { BusinessPartner } from "../../../api/getBusinessPartner";

export const useBusinessPartnerOptions = (data: BusinessPartner[]) => {
  return useMemo(() => {
    if (!data) {
      return [];
    }
    return data.map((bp) => {
      let businessID = bp.id.toString();
      // If we specify no business partner "取引先なし" (id: 0) in the filter, we should set "without" value for the backend
      if (bp.id === 0) {
        businessID = "without";
      }
      return {
        value: businessID,
        label: `${bp.name}`,
        name_for_search: bp.name_for_search,
        identification_code: bp.code,
        encrypted_id: bp.encrypted_id,
      };
    });
  }, [data]);
};
