export interface DefaultJournalStatus {
  statuses: string[];
}

// api endpoint for saving the default journal statuses for the project report
// here 9 is the report type id for project report
const defaultJournalStatusURL = "/api/default_search_approval_statuses/9";

export async function fetchDefaultJournalStatuses() {
  return fetch(defaultJournalStatusURL)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      return response.json() as Promise<DefaultJournalStatus>;
    })
    .catch((error) => {
      throw error;
    });
}

export async function putDefaultJournalStatuses(currentStatuses: number[]) {
  return fetch(defaultJournalStatusURL, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ statuses: currentStatuses }),
  });
}
