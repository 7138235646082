import React from "react";

export const DropDownIcon = () => {
  return (
    <svg
      className="dropdown-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M7 10L12 15L17 10H7Z" />
    </svg>
  );
};
