import { QueryClient } from "react-query";

const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // TODO: in future, we may add staleTime and cacheTime, but currently its not a requirement
      refetchOnWindowFocus: false, // Disables automatic re fetching when browser window is focused.
      refetchOnMount: true, // run the query when the app is refreshed
    },
  },
});

export { reactQueryClient };
