import { useMemo } from "react";
import { ProjectDetails } from "../../../api/getProjects";
import { TermDetails } from "../../../api/getTermDetails";

//  The project API fetches data containing all the projects(with in terms, and outside of terms as well).
//  Active project is projects falling within the term.
// This function filters the projects which lie in the present term
const isProjectActiveInCurrentTerm = (
  project: ProjectDetails,
  termDetails?: TermDetails
) => {
  if (!termDetails) {
    // If term details are not available, we cannot determine if the project is active in the current term,
    // so we choose to display it
    return true;
  }
  const projectStartDate = new Date(project.valid_from);
  const projectEndDate = new Date(project.valid_to);
  const termStartDate = new Date(termDetails.start_date);
  const termEndDate = new Date(termDetails.end_date);
  return (
    projectEndDate.getTime() >= termStartDate.getTime() &&
    projectStartDate.getTime() <= termEndDate.getTime()
  );
};

// Converts the project data received from the API to ProjectDetails[]
export const ParseProjectOptions = (
  data?: ProjectDetails[],
  termDetails?: TermDetails
) => {
  return useMemo(() => {
    if (!data) {
      return [];
    }
    return data
      .filter((project) => isProjectActiveInCurrentTerm(project, termDetails))
      .map((project) => {
        return {
          value: project.biid,
          label: project.name,
          identification_code: project.code,
          name_for_search: project.name,
          encrypted_id: "",
        };
      });
  }, [data, termDetails]);
};
