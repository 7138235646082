import React, { useEffect, useState } from "react";
import "./FilterContainer.scss";
import { JournalTypeFilter } from "./JournalTypeFilter/JournalTypeFilter";
import { MonthFilter } from "./MonthFilter/MonthFilter";
import { BusinessPartnerFilter } from "./BusinessPartner/BusinessPartnerFilter";
import { JournalStatusFilter } from "./JournalStatus/JournalStatusFilter";
import {
  ItemFilter,
  getAllOptionsFlat as getAllOptionsFromGroup,
  ItemWithCategoryOption,
  ItemOption,
} from "./ItemFilter/ItemFilter";
import {
  getAllSubItemOptionsFlat,
  SubItemFilter,
} from "./ItemFilter/SubItemFilter";
import { useItemAndSubItemOptions } from "./ItemFilter/useItemAndSubItemOptions";
import { ProjectFilter } from "./ProjectFilter/ProjectFilter";
import { ParseProjectOptions } from "./ProjectFilter/parseProjectOptions";
import { useDepartmentOptions } from "./DepartmentFilter/useDepartmentOptions";
import {
  DepartmentFilter,
  getAllOptionsFlat,
} from "./DepartmentFilter/DepartmentFilter";
import {
  Option,
  useFilterConfirmedState,
} from "./Helpers/useFilterConfirmedState";
import { prepareDepartmentsForServer } from "./DepartmentFilter/prepareDepartmentsForServer";
import { MasterData } from "../../FilterContext";
import { useBusinessPartnerOptions } from "./BusinessPartner/useBusinessPartnerOptions";
import { useConvertTermDetailToTermInfo } from "./MonthFilter/useConvertTermDetailToTermInfo";
import DisplaySubItemFilter from "./DisplaySubItemFilter/DisplaySubItemFilter";
import TaxIncludeExcludeFilter from "./TaxIncludeExcludeFilter/TaxIncludeExcludeFilter";
import Pagination from "./Pagination/Pagination";
import { ProjectReportAction, State } from "../../useProjectReportReducer";
import { updateURLWithFiltersStates } from "./URLProcessing/ConfirmedFilterStateToURLState";
import {
  extractURLParams,
  validateInitialDisplayOption,
  validateInitialMonthState,
  validateInitialState,
  validateInitialTaxOption,
} from "./URLProcessing/URLStateToFilter";
import { DrillDownNoticeMessage } from "../ReportTable/DrillDownBuilder";
import { FilterSelection } from "./Helpers/FilterSelection";

interface FilterContainerProps {
  masterData: MasterData;
  urlSearchParams: URLSearchParams;
  dispatch: React.Dispatch<ProjectReportAction>;
  state: State;
  isTableDataLoading: boolean;
  queryParams: string | null;
  totalProject: number;
}

const FilterContainer = ({
  masterData,
  urlSearchParams,
  dispatch,
  state,
  isTableDataLoading,
  queryParams,
  totalProject,
}: FilterContainerProps) => {
  const urlParams = extractURLParams(urlSearchParams);
  // if filterStateString changes, page will be updated
  const [filterStateString, setFilterStateString] = useState<string>(
    JSON.stringify(state.filterState)
  );
  // month filter
  const termInfo = useConvertTermDetailToTermInfo(masterData.termDetails);
  const initialMonths = validateInitialMonthState(termInfo, urlParams.Dates);
  const [selectedMonths, setSelectedMonths] = useState<string[]>(initialMonths);

  // journal type filter
  const journalTypes: Option[] = [
    { value: "1", label: "通常仕訳" },
    { value: "2", label: "決算整理仕訳" },
  ];
  const validInitialJournalType = validateInitialState(
    journalTypes,
    urlParams.JournalTypes
  );
  const [selectedJournalTypes, setSelectedJournalTypes] =
    useFilterConfirmedState(journalTypes, validInitialJournalType);

  // journal status filter
  const journalStatuses: Option[] = [
    { value: "1", label: "未申請" },
    { value: "2", label: "差戻し" },
    { value: "3", label: "申請中" },
    { value: "4", label: "承認済" },
  ];
  const validInitialJournalStatus = validateInitialState(
    journalStatuses,
    urlParams.JournalStatuses
  );
  // if there is no journal status from url, we need to set it with default journal status
  const initialJournalStatus = validInitialJournalStatus.length
    ? validInitialJournalStatus
    : masterData?.defaultJournalStatuses?.statuses.map(String);
  const [selectedJournalStatuses, setSelectedJournalStatuses] =
    useFilterConfirmedState(journalStatuses, initialJournalStatus);

  // item filter
  const ItemWithCategoryOptions = useItemAndSubItemOptions(
    masterData.itemData,
    masterData.subItemData
  );
  const flatItemOptions = getAllOptionsFromGroup(ItemWithCategoryOptions);
  const validInitialItems = validateInitialState(
    flatItemOptions,
    urlParams.Items
  );
  const [selectedItems, setSelectedItems] = useFilterConfirmedState(
    flatItemOptions,
    validInitialItems
  );

  // sub item filter
  const getItemOptionsFromCategories = (
    options: ItemWithCategoryOption[]
  ): ItemOption[] => {
    return options
      .map((category) => {
        return category.options.flat();
      })
      .flat();
  };
  const ItemAndSubItemOptions = getItemOptionsFromCategories(
    ItemWithCategoryOptions
  );
  const flatSubItemOptions = getAllSubItemOptionsFlat(
    getAllOptionsFromGroup(ItemWithCategoryOptions)
  );
  const validInitialSubItem = validateInitialState(
    flatSubItemOptions,
    urlParams.SubItems
  );
  const [selectedSubItems, setSelectedSubItems] = useFilterConfirmedState(
    flatSubItemOptions,
    validInitialSubItem
  );

  // department filter
  const departmentOptions = useDepartmentOptions(masterData.departments);
  const flatDepartments = getAllOptionsFlat(departmentOptions);
  const validInitialDepartments = validateInitialState(
    flatDepartments,
    urlParams.Departments
  );
  const [selectedDepartments, setSelectedDepartments] = useFilterConfirmedState(
    flatDepartments,
    validInitialDepartments
  );
  // We need to convert the selected departments to the format that the backend can use to render Headers properly
  // Check `checkParentWithChildren` function for details
  // We can’t update the state that we use inside the filter directly, because there is no option with `ID_only` value.
  const [departmentsForServer, setDepartmentsForServer] =
    useState<FilterSelection>([]);
  useEffect(() => {
    setDepartmentsForServer(
      prepareDepartmentsForServer(selectedDepartments, departmentOptions)
    );
  }, [selectedDepartments, departmentOptions]);

  // project filter
  const projectOption = ParseProjectOptions(
    masterData.projects,
    masterData.termDetails
  );
  const validInitialProject = validateInitialState(
    projectOption,
    urlParams.Projects
  );
  const [selectedProjects, setSelectedProjects] = useFilterConfirmedState(
    projectOption,
    validInitialProject
  );

  // business partner filter
  const businessPartnersOptions = useBusinessPartnerOptions(
    masterData.businessPartners
  );
  const validInitialBusinessPartners = validateInitialState(
    businessPartnersOptions,
    urlParams.BusinessPartners
  );
  const [selectedBusinessPartners, setSelectedBusinessPartners] =
    useFilterConfirmedState(
      businessPartnersOptions,
      validInitialBusinessPartners
    );

  // displaySubItemOption
  const initialDisplaySubItemOption = validateInitialDisplayOption(
    urlParams.displaySubItemOption
  );
  const [displaySubItemOption, setDisplaySubItemOption] = useState<string>(
    initialDisplaySubItemOption
  );

  // taxFilter
  const initialTaxOption = validateInitialTaxOption(
    urlParams.TaxFilter,
    masterData?.termDetails?.include_tax_id
  );
  const [selectedTaxFilter, setSelectedTaxFilter] =
    useState<string>(initialTaxOption);

  useEffect(() => {
    const filterSelectionToString = (filterSelection: FilterSelection) => {
      return filterSelection === "all" ? [] : (filterSelection as string[]);
    };

    const newFilterState = {
      journalTypes: filterSelectionToString(selectedJournalTypes),
      journalStatuses: filterSelectionToString(selectedJournalStatuses),
      departments: filterSelectionToString(departmentsForServer),
      businessPartners: filterSelectionToString(selectedBusinessPartners),
      itemIDs: filterSelectionToString(selectedItems),
      subItemIDs: filterSelectionToString(selectedSubItems),
      projectBIIDs: filterSelectionToString(selectedProjects),
      displaySubItems: displaySubItemOption,
      taxFilter: selectedTaxFilter,
      startDate: selectedMonths.length ? selectedMonths[0] : null,
      endDate: selectedMonths.length ? selectedMonths[1] : null,
    };

    dispatch({
      type: "UPDATE_FILTER_STATE",
      payload: {
        filterState: newFilterState,
      },
    });

    setFilterStateString(JSON.stringify(newFilterState));
    // update the url params with the filter state
    updateURLWithFiltersStates(urlSearchParams, {
      JournalTypes: selectedJournalTypes,
      JournalStatuses: selectedJournalStatuses,
      BusinessPartners: selectedBusinessPartners,
      Items: selectedItems,
      SubItems: selectedSubItems,
      Projects: selectedProjects,
      Departments: selectedDepartments,
      Dates: selectedMonths,
      TaxFilter: selectedTaxFilter,
      DisplaySubItemOption: displaySubItemOption,
    });
  }, [
    selectedJournalTypes,
    selectedJournalStatuses,
    selectedDepartments,
    selectedBusinessPartners,
    selectedItems,
    selectedSubItems,
    selectedProjects,
    displaySubItemOption,
    selectedMonths,
    selectedTaxFilter,
    dispatch,
    urlSearchParams,
    departmentsForServer,
  ]);

  useEffect(() => {
    dispatch({
      type: "UPDATE_PAGE",
      payload: { page: 1 },
    });
  }, [dispatch, filterStateString]);

  return (
    <div className="filter-v2-container-base">
      <div className="horizontal-box-v2">
        <MonthFilter
          termInfo={termInfo}
          confirmedSelection={selectedMonths}
          setConfirmedSelection={setSelectedMonths}
        />
        <JournalTypeFilter
          options={journalTypes}
          confirmedSelection={selectedJournalTypes}
          setConfirmedSelection={setSelectedJournalTypes}
        />
        <JournalStatusFilter
          options={journalStatuses}
          confirmedSelection={selectedJournalStatuses}
          setConfirmedSelection={setSelectedJournalStatuses}
        />
      </div>
      <div className="horizontal-box-v2">
        <ItemFilter
          options={ItemWithCategoryOptions}
          confirmedSelection={selectedItems}
          setConfirmedSelection={setSelectedItems}
        />
        <SubItemFilter
          options={ItemAndSubItemOptions}
          confirmedSelection={selectedSubItems}
          setConfirmedSelection={setSelectedSubItems}
          SelectedItems={selectedItems}
        />
        <DepartmentFilter
          parentOptions={departmentOptions}
          confirmedSelection={selectedDepartments}
          setConfirmedSelection={setSelectedDepartments}
        />
        <ProjectFilter
          options={projectOption}
          confirmedSelection={selectedProjects}
          setConfirmedSelection={setSelectedProjects}
        />
        <BusinessPartnerFilter
          options={businessPartnersOptions}
          confirmedSelection={selectedBusinessPartners}
          setConfirmedSelection={setSelectedBusinessPartners}
        />
      </div>
      {!queryParams && !isTableDataLoading && <DrillDownNoticeMessage />}
      <div className="select-filters">
        <div className="select-filter-checkbox">
          <DisplaySubItemFilter
            selectedOption={displaySubItemOption}
            setDisplaySubItemGlobal={setDisplaySubItemOption}
          />
          <TaxIncludeExcludeFilter
            selectedOption={selectedTaxFilter}
            setTaxFilterGlobal={setSelectedTaxFilter}
            includeTaxId={masterData?.termDetails?.include_tax_id}
          />
        </div>
        <Pagination
          totalProjectNumber={totalProject}
          onPageChange={(page: number) => {
            dispatch({
              type: "UPDATE_PAGE",
              payload: { page },
            });
          }}
          currentPage={state.page || 1}
        />
      </div>
    </div>
  );
};

export default FilterContainer;
