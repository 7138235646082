import React from "react";
import {
  IconButtonExpand,
  IconButtonCollapse,
} from "../../CommonComponents/IconButton/IconButton";

export const ExpandAllButton = ({
  allProjectsExpanded,
  onExpandAll,
  onCollapseAll,
}: {
  allProjectsExpanded: boolean;
  onExpandAll: () => void;
  onCollapseAll: () => void;
}) => {
  return (
    <div style={{ padding: "0 14px" }}>
      {allProjectsExpanded ? (
        <IconButtonCollapse onPress={onCollapseAll} />
      ) : (
        <IconButtonExpand onPress={onExpandAll} />
      )}
    </div>
  );
};
