import React, { useContext } from "react";
import {
  DownloadStatus,
  fetchAndDownloadFile,
} from "../../helpers/downloadFile";
import "./CSVExport.css";
import { Button } from "../Button/Button";
import {
  DisplayMessageContext,
  MessageType,
  NewMessage,
} from "../DisplayAlert/DisplayMessageProvider";
import { State } from "../../useProjectReportReducer";

const serverErrorMessage = NewMessage.bind(
  null,
  // A server error has occurred. Please wait a moment and try again.
  "サーバーエラーが発生しました。しばらく時間をおいてから再度お試しください。",
  MessageType.Error
);

const authenticationErrorMessage = NewMessage.bind(
  null,
  // An authorization error has occurred. Contact your system administrator.
  "権限エラーが発生しました。システム管理者に問い合わせてください。",
  MessageType.Error
);

interface FileDownloadParameters {
  search_date_from?: string;
  search_date_to?: string;
  journal_type?: string;
  journal_statuses?: string;
  department_ids?: string;
  business_partner_ids?: string;
  item_ids?: string;
  sub_item_ids?: string;
  show_sub_items?: string;
  include_tax?: string;
  project_biids?: string;
}

function convertStateToFileDownloadParameters(
  state: State
): FileDownloadParameters {
  const parameters: FileDownloadParameters = {};
  if (state.filterState.startDate && state.filterState.endDate) {
    parameters.search_date_from = state.filterState.startDate;
    parameters.search_date_to = state.filterState.endDate;
  }

  if (state.filterState.journalTypes.length) {
    parameters.journal_type = state.filterState.journalTypes.join(",");
  }

  if (state.filterState.journalStatuses.length) {
    parameters.journal_statuses = state.filterState.journalStatuses.join(",");
  }

  if (state.filterState.departments.length) {
    parameters.department_ids = state.filterState.departments.join(",");
  }

  if (state.filterState.businessPartners.length) {
    parameters.business_partner_ids =
      state.filterState.businessPartners.join(",");
  }

  if (state.filterState.itemIDs.length) {
    parameters.item_ids = state.filterState.itemIDs.join(",");
  }

  if (state.filterState.subItemIDs.length) {
    parameters.sub_item_ids = state.filterState.subItemIDs.join(",");
  }

  // update project filters
  if (state.filterState.projectBIIDs.length) {
    parameters.project_biids = state.filterState.projectBIIDs.join(",");
  }
  // update the url for taxFilter
  if (state.filterState.taxFilter.length) {
    parameters.include_tax = state.filterState.taxFilter;
  }

  // update the url for
  if (state.filterState.displaySubItems.length) {
    parameters.show_sub_items = state.filterState.displaySubItems;
  }

  return parameters;
}

const CSVExport = ({ state }: { state: State }): JSX.Element => {
  const { addMessage } = useContext(DisplayMessageContext);
  const handleClick = () => {
    // TODO: put into config
    const fileURL = "/accounting_report/export_project_report?file_type=csv";
    // this file name will be used if we didn't get file name from server
    const backUpFileName = `プロジェクト別集計表`;
    const parameters = convertStateToFileDownloadParameters(state);

    fetchAndDownloadFile(fileURL, backUpFileName, parameters)
      .then((response) => {
        if (response.status === DownloadStatus.Success) return;

        console.error(
          `Project report: CSV export error: ${response.status} ${response.response.statusText}`
        );
        if (response.response.status === 403) {
          addMessage(authenticationErrorMessage());
        } else if (response.status === DownloadStatus.AuthenticationError) {
          // if user is logged out => redirect him to the login page
          window.location.href = response.response.url;
        } else {
          addMessage(serverErrorMessage());
        }
      })
      .catch((exception: Error) => {
        console.error(`Project report: CSV export error: ${exception.message}`);
        addMessage(serverErrorMessage());
      });
  };
  return (
    <Button handleClick={handleClick}>
      <div className="export-button-text">CSVエクスポート</div>
    </Button>
  );
};

export { CSVExport };
