import React from "react";
import { DepartmentOption } from "./DepartmentFilter";
import {
  parentDepartmentSuffix,
  parentOnlyDepartmentSuffix,
} from "./useDepartmentOptions";
import { FilterSelection } from "../Helpers/FilterSelection";

// we need it to distinguish between cases where
// single（共通）option was selected -> don't add _only suffix
// （共通）option + other child departments were selected -> add _only suffix
//
// we need it to show correct values in CSV file in headers, more details in getDepartmentNames function in the backend code
// https://github.com/moneyforward/ca_mid_project_report_backend/blob/27021e3ee6d18092013e9bf3739fb9a866231a13/internal/services/headers.go#L121
// Especially with #3 and #5 cases:
//  // 3. parent department selected （共通）without children
// 	// C部門
//  // 5. parent department selected （共通）without some children, but not all
//  // D部門（共通）,E部門
//
// To tell backend when to print （共通）we need to manage _only suffix in the frontend

export function prepareDepartmentsForServer(
  // selected departments in sorted order (parent department first, children after)
  selectedDepartments: FilterSelection,
  departmentOptions: DepartmentOption[]
): FilterSelection {
  if (selectedDepartments === "all") return "all";
  // parent id => children values Map
  const parentDepartmentMap: Map<React.Key, Set<React.Key>> = new Map();
  departmentOptions.forEach((department) => {
    if (department.value.includes(parentDepartmentSuffix))
      parentDepartmentMap.set(
        department.value.replace(parentDepartmentSuffix, ""),
        new Set(department.children?.map((option) => option.value))
      );
  });
  return selectedDepartments.map((departmentKey, index) => {
    // departmentKey without any suffix means that it's child departmentKey or common（共通）option
    const parent = parentDepartmentMap.get(departmentKey);
    // if it is exists in parentDepartmentMap it means that it's common（共通）option
    if (parent && parent.size > 0 && index < selectedDepartments.length - 1) {
      // here we check that next selected Key in the list is child of departmentKey
      if (parent.has(selectedDepartments[index + 1])) {
        // If it is  common（共通）option selected with some child departments we need to add _only suffix
        return departmentKey.toString() + parentOnlyDepartmentSuffix;
      }
    }
    return departmentKey;
  });
}
