import { Button } from "react-aria-components";
import React from "react";
import "./FilterFooter.scss";

export const FilterFooter = ({
  onOK,
  onCancel,
}: {
  onOK: () => void;
  onCancel: () => void;
}) => {
  return (
    <div className="filter-FilterButtonFooter">
      <Button
        className="react-aria-SubmitButton cancelButton"
        onPress={onCancel}
      >
        キャンセル
      </Button>
      <Button className="react-aria-SubmitButton okButton" onPress={onOK}>
        OK
      </Button>
    </div>
  );
};
