import React, { useCallback, useEffect, useState } from "react";

interface OptionProps {
  // The distance between the bottom of the scrollable container and the bottom of the content.
  threshold: number;
  // The number of items to render initially.
  firstRenderAmount: number;
  // The number of items to render each time.
  renderEachTime: number;
}

const defaultOptions: OptionProps = {
  threshold: 20,
  // Warning: This number must be greater than can fit in the container to scrolling works!!!
  firstRenderAmount: 10,
  renderEachTime: 10,
};

export const useInfiniteScroll = <T,>(
  data: T[],
  options?: Partial<OptionProps>
) => {
  const [visibleOptions, setVisibleOptions] = useState<T[]>([]);

  const { threshold, firstRenderAmount, renderEachTime } = {
    ...defaultOptions,
    ...options,
  };

  useEffect(() => {
    const newRenderOptions = data.slice(0, firstRenderAmount);
    setVisibleOptions(newRenderOptions);
  }, [data, firstRenderAmount]);

  const onHandleScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      if (visibleOptions.length >= data.length) return;
      if (event.target) {
        const { scrollTop, clientHeight, scrollHeight } =
          event.target as HTMLElement;
        if (scrollHeight - scrollTop - clientHeight <= threshold) {
          const newRenderOptions = data.slice(
            visibleOptions.length,
            visibleOptions.length + renderEachTime
          );
          setVisibleOptions((prevOptions) => [
            ...prevOptions,
            ...newRenderOptions,
          ]);
        }
      }
    },
    [data, visibleOptions, threshold, renderEachTime]
  );

  return { visibleOptions, onHandleScroll };
};
