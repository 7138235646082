import React, { ChangeEvent } from "react";
import "./RadioButton.css";

interface RadioButtonProps {
  label: string;
  value: string;
  isChecked: boolean;
  onRadioChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton = ({
  label,
  value,
  isChecked,
  onRadioChange,
}: RadioButtonProps) => {
  return (
    <div className="radio-button">
      <label htmlFor={`radio_${value}`}>
        <input
          type="radio"
          value={value}
          checked={isChecked}
          onChange={onRadioChange}
          data-testid={`radio_${label}_${value}`}
          id={`radio_${value}`}
        />
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
