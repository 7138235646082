import React, { useContext, useEffect, useReducer, useState } from "react";
import { useQuery } from "@apollo/client";
import { CSVExport } from "./components/CSVExport/CSVExport";
import ReportView from "./components/ReportTable/ReportView";
import "./App.css";
import FilterContainer from "./components/ReportFilter/FilterContainer";
import { MasterDataContext } from "./FilterContext";
import { State, useProjectReportReducer } from "./useProjectReportReducer";
import {
  GetProjectDetailsQuery,
  JournalStatus,
  JournalType,
} from "./__generated__/graphql";
import GET_PROJECT from "./queries/ProjectQuery";
import { LoadingSpinner } from "./components/LoadingSpinner/LoadingSpinner";
import { TAX_EXCLUDE } from "./components/ReportFilter/TaxIncludeExcludeFilter/TaxIncludeExcludeFilter";
import { BuildDrillDownParameters } from "./components/ReportTable/DrillDownBuilder";

// prepareJournalTypes: this function converts the html form value to desired
// we are using the common filter, both csvExport/Tables. So we need to parse here
// according to the graphql requirement
const prepareJournalTypes = (selectedJournalTypes: string[]) => {
  const journalTypes: JournalType[] = [];
  selectedJournalTypes.forEach((journalType) => {
    if (journalType === "1") {
      journalTypes.push(JournalType.NormalJournal);
    } else if (journalType === "2") {
      journalTypes.push(JournalType.ClosingJournal);
    }
  });

  return journalTypes;
};

// prepareJournalStatus: this function converts the html form value to desired
// we are using the common filter, both csvExport/Tables. So we need to parse here
// according to the graphql requirement
const prepareJournalStatus = (selectedJournalStatuses: string[]) => {
  const journalStatuses: JournalStatus[] = [];
  selectedJournalStatuses.forEach((journalStatus) => {
    if (journalStatus === "1") {
      journalStatuses.push(JournalStatus.DraftStatus);
    } else if (journalStatus === "2") {
      journalStatuses.push(JournalStatus.RejectedStatus);
    } else if (journalStatus === "3") {
      journalStatuses.push(JournalStatus.ApplyingStatus);
    } else if (journalStatus === "4") {
      journalStatuses.push(JournalStatus.ApprovedStatus);
    }
  });

  return journalStatuses;
};

const buildGraphQLQueryParameters = (state: State) => {
  const { filterState, page } = state;

  // transform journalTypes and journalStatus
  const journalStateTransformed = {
    journalTypes: prepareJournalTypes(filterState.journalTypes),
    journalStatuses: prepareJournalStatus(filterState.journalStatuses),
  };

  return {
    ...filterState,
    ...journalStateTransformed,
    showSubItems: filterState.displaySubItems,
    page,
  };
};

const useGraphQLQuery = (state: State) => {
  const variables = buildGraphQLQueryParameters(state);
  return useQuery<GetProjectDetailsQuery>(GET_PROJECT, {
    variables,
    fetchPolicy: "no-cache",
  });
};

// TODO: We need to extra and validate data's from urlSearchParams later
const createInitialState = (): State => {
  const filterState = {
    journalTypes: [],
    journalStatuses: [],
    departments: [],
    businessPartners: [],
    itemIDs: [],
    subItemIDs: [],
    projectBIIDs: [],
    taxFilter: TAX_EXCLUDE,
    displaySubItems: "true",
    startDate: null,
    endDate: null,
  };

  return {
    filterState,
    page: 1,
    expandedProjects: new Set<string>(),
  };
};

const App = () => {
  const { masterData, isMasterDataLoading, urlSearchParams, fetchErrorList } =
    useContext(MasterDataContext);
  if (fetchErrorList) {
    fetchErrorList.forEach((error) => {
      // TODO: need to display some error message like `addMessage` from DisplayMessageContext
      console.error(`Project report: Fetch Error: ${error.message}`);
    });
  }
  const [state, dispatch] = useReducer(
    useProjectReportReducer,
    createInitialState()
  );
  const { loading, error, data } = useGraphQLQuery(state);
  if (error) {
    // TODO: need to display some error message like `addMessage` from DisplayMessageContext
    console.log("project report: GraphQL error: ", error);
  }
  // totalProject is the total number of projects queried,it`s required for pagination
  // we need this extra state to avoid re-rending of the component
  const [totalProject, setTotalProject] = useState<number>(0);
  useEffect(() => {
    if (data?.project.totalProjects || data?.project.totalProjects === 0) {
      setTotalProject(data?.project.totalProjects);
    }
  }, [data?.project.totalProjects]);
  const queryParams = BuildDrillDownParameters(
    state,
    masterData.departments,
    masterData.businessPartners
  );

  if (isMasterDataLoading) {
    // TODO: need to confirm about when to load spinner
    return <LoadingSpinner size="xLarge" />;
  }

  return (
    <div className="app-body">
      <div className="app-title-header">
        <div className="app-title">プロジェクト別集計表</div>
        <CSVExport state={state} />
      </div>
      <div role="region" aria-label="filter-container">
        <FilterContainer
          masterData={masterData}
          urlSearchParams={urlSearchParams}
          dispatch={dispatch}
          state={state}
          isTableDataLoading={loading}
          queryParams={queryParams}
          totalProject={totalProject}
        />
      </div>
      <div>
        {loading ? (
          <LoadingSpinner size="xLarge" />
        ) : (
          <ReportView
            projects={data?.project?.projects}
            state={state}
            dispatch={dispatch}
            queryParams={queryParams}
          />
        )}
      </div>
    </div>
  );
};

export default App;
