// TODO: the app comments contain only dummy value as of now,we have to change it later.
import React from "react";
import "./Button.css";

interface ButtonInterface {
  handleClick: () => void;
  children: React.ReactElement;
}

// TODO: this is basic implementation for button, in future we may use something like this:
// https://github.com/moneyforward/ca_mid_web_frontend/blob/main/packages/core/src/components/inputs/Button/index.tsx
const Button = ({ handleClick, children }: ButtonInterface): JSX.Element => {
  return (
    <button type="button" className="button" onClick={handleClick}>
      {children}
    </button>
  );
};

export { Button };
