import React, { ChangeEvent, useEffect, useState } from "react";
import RadioButton from "../../CommonComponents/RadioButton/RadioButton";
import "./TaxIncludeExcludeFilter.css";

export const TAX_EXCLUDE = "tax_exclude";
export const TAX_INCLUDE = "tax_include";
export const TAX_EXCLUDE_INSIDE = "tax_excluded_inside";

interface TaxIncludeExcludeFilterProps {
  setTaxFilterGlobal: (taxFilter: string) => void;
  selectedOption: string;
  includeTaxId?: string;
}
const TaxIncludeExcludeFilter = ({
  setTaxFilterGlobal,
  selectedOption,
  includeTaxId,
}: TaxIncludeExcludeFilterProps) => {
  // Tax filter should be shown when user's tax setting(termDetails.include_tax_id) = TAX_EXCLUDE_INSIDE
  let showComponent = false;
  if (includeTaxId && includeTaxId === TAX_EXCLUDE_INSIDE) {
    showComponent = true;
  }

  const [taxOption, setTaxOption] = useState<string>(
    selectedOption || TAX_EXCLUDE
  );
  useEffect(() => {
    setTaxOption(selectedOption || TAX_EXCLUDE);
  }, [selectedOption]);
  // const [showComponent, setShowComponent] = useState<boolean>(false);

  const handleTaxOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTaxOption(event.target.value);
  };

  // useEffect to update the global taxOptions with the current tax options
  useEffect(() => {
    setTaxFilterGlobal(taxOption);
  }, [setTaxFilterGlobal, taxOption]);

  return (
    <div>
      {showComponent ? (
        <div className="tax-include-exclude-form">
          <p>消費税：</p>
          <div className="tax-include-exclude-options">
            <RadioButton
              label="税抜"
              value={TAX_EXCLUDE}
              isChecked={taxOption === TAX_EXCLUDE}
              onRadioChange={handleTaxOptionChange}
            />

            <RadioButton
              label="税込"
              value={TAX_INCLUDE}
              isChecked={taxOption === TAX_INCLUDE}
              onRadioChange={handleTaxOptionChange}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TaxIncludeExcludeFilter;
