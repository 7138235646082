import React, { useMemo } from "react";
import { useQuery } from "react-query";
import {
  fetchItemsWithCategory,
  ItemWithCategory,
} from "./api/getItemWithCategory";
import {
  BusinessPartner,
  fetchBusinessPartners,
} from "./api/getBusinessPartner";
import { fetchProjects, ProjectDetails } from "./api/getProjects";
import { Department, fetchDepartments } from "./api/getDepartment";
import { fetchTermDetails, TermDetails } from "./api/getTermDetails";
import {
  DefaultJournalStatus,
  fetchDefaultJournalStatuses,
} from "./api/defaultJournalStatus";
import { fetchSubItemsDetails, ItemWithSubItems } from "./api/getSubItems";

export interface MasterData {
  itemData: ItemWithCategory[];
  businessPartners: BusinessPartner[];
  projects: ProjectDetails[];
  departments: Department[];
  termDetails: TermDetails;
  defaultJournalStatuses: DefaultJournalStatus;
  subItemData: ItemWithSubItems[];
}
const initialMasterData: MasterData = {
  itemData: [],
  businessPartners: [],
  projects: [],
  departments: [],
  termDetails: {
    encrypted_id: "",
    term_months: [],
    start_date: "",
    end_date: "",
    include_tax_id: "",
  },
  defaultJournalStatuses: {
    statuses: [],
  },
  subItemData: [],
};
/**
 * Custom React Hook to load master data using React Query.
 * @returns Object containing master data, loading state, and error list.
 * All the queries are executed in parallel. finally consolidate the results and return the overall data,loading state and error list.
 */
export const useLoadMasterData = () => {
  const itemQuery = useQuery<ItemWithCategory[], Error>(
    "itemData",
    fetchItemsWithCategory
  );
  const businessPartnersQuery = useQuery<BusinessPartner[], Error>(
    "businessPartners",
    fetchBusinessPartners
  );
  const projectsQuery = useQuery<ProjectDetails[], Error>(
    "projects",
    fetchProjects
  );
  const departmentsQuery = useQuery<Department[], Error>(
    "departments",
    fetchDepartments
  );
  const termDetailsQuery = useQuery<TermDetails, Error>(
    "termDetails",
    fetchTermDetails
  );
  const defaultJournalStatusesQuery = useQuery<DefaultJournalStatus, Error>(
    "defaultJournalStatuses",
    fetchDefaultJournalStatuses
  );

  const itemIDs = itemQuery.data
    ? itemQuery.data?.flatMap((group) =>
        group?.items?.map((item) => item?.id.toString())
      )
    : [];

  const subItemQuery = useQuery<ItemWithSubItems[], Error>(
    "subItems",
    () => fetchSubItemsDetails(itemIDs),
    {
      enabled: !!itemQuery.data && itemIDs.length > 0,
    }
  );

  const isLoading =
    itemQuery.isLoading ||
    businessPartnersQuery.isLoading ||
    projectsQuery.isLoading ||
    departmentsQuery.isLoading ||
    termDetailsQuery.isLoading ||
    defaultJournalStatusesQuery.isLoading ||
    subItemQuery.isLoading;

  const errorList = [
    itemQuery.error,
    businessPartnersQuery.error,
    projectsQuery.error,
    departmentsQuery.error,
    termDetailsQuery.error,
    defaultJournalStatusesQuery.error,
    subItemQuery.error,
  ].filter((error): error is Error => error !== null);

  return {
    masterData: {
      itemData: itemQuery.data,
      businessPartners: businessPartnersQuery.data,
      projects: projectsQuery.data,
      departments: departmentsQuery.data,
      termDetails: termDetailsQuery.data,
      defaultJournalStatuses: defaultJournalStatusesQuery.data,
      subItemData: subItemQuery.data,
    } as MasterData,
    isLoading,
    fetchErrorList: errorList,
  };
};

export interface MasterDataContextProps {
  masterData: MasterData;
  isMasterDataLoading: boolean;
  urlSearchParams: URLSearchParams;
  fetchErrorList?: Error[];
}
export const MasterDataContext = React.createContext<MasterDataContextProps>({
  masterData: initialMasterData,
  isMasterDataLoading: true,
  urlSearchParams: new URLSearchParams(),
});
export const MasterDataContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { masterData, isLoading, fetchErrorList } = useLoadMasterData();
  const value = useMemo(() => {
    return {
      masterData,
      isMasterDataLoading: isLoading,
      urlSearchParams: new URLSearchParams(window.location.search),
      fetchErrorList,
    };
  }, [masterData, isLoading, fetchErrorList]);

  return (
    <MasterDataContext.Provider value={value}>
      {children}
    </MasterDataContext.Provider>
  );
};
