import React, { memo, useMemo } from "react";
import { Separator, Text } from "react-aria-components";
import {
  allOptionsSelected,
  GetFilterButtonCounter,
} from "../../CommonComponents/FilterButtonWithMenu/GetFilterButtonText";
import { useSearch } from "../../CommonComponents/SearchField/useSearch";
import { FilterButtonWithMenu } from "../../CommonComponents/FilterButtonWithMenu/FilterButtonWithMenu";
import SearchField from "../../CommonComponents/SearchField/SearchField";
import {
  FilterItem,
  FilterMenu,
} from "../../CommonComponents/FilterButtonWithMenu/FilterMenu";
import { NoOptionsText } from "../Helpers/NoOptionsText";
import { useSelectedWithNestedSelectAll } from "../../CommonComponents/FilterButtonWithMenu/useSelectedWithNestedSelectAll";
import { hasSelection } from "../Helpers/useFilterConfirmedState";
import { FilterSelection } from "../Helpers/FilterSelection";
import { useInfiniteScroll } from "../../CommonComponents/FilterButtonWithMenu/useInfiniteScroll";

export interface DepartmentOption {
  value: string;
  label: string;
  name_for_search: string;
  identification_code: string;
  is_common: boolean;
  encrypted_id: string;
  children?: DepartmentOption[];
}
export function getAllOptionsFlat(options: DepartmentOption[]) {
  // unpack all options and keep them in order
  return options.map((option) => [option, ...(option.children || [])]).flat();
}

const DepartmentWithChildren = memo(
  ({ option }: { option: DepartmentOption }) => {
    return (
      <>
        <FilterItem id={option.value}>
          <Text slot="label">{option.label}</Text>
          <Text slot="description">{option.identification_code}</Text>
        </FilterItem>
        {option.children?.map((childOption) => {
          return (
            <FilterItem
              id={childOption.value}
              key={childOption.value}
              indentation={1}
            >
              <Text slot="label">{childOption.label}</Text>
              <Text slot="description">{childOption.identification_code}</Text>
            </FilterItem>
          );
        })}
      </>
    );
  }
);

// Reconstruct department options with departments after filtering
function getFilteredOptions(
  filteredOptionsFlat: DepartmentOption[],
  parentOptions: DepartmentOption[]
) {
  const filteredOptions: DepartmentOption[] = [];
  const filteredOptionsSet = new Set(filteredOptionsFlat);
  parentOptions.forEach((parent) => {
    const filteredChildren = parent.children?.filter((child) =>
      filteredOptionsSet.has(child)
    );
    // if after filtering parent or any of its children is left, we add it to the list
    if (filteredOptionsSet.has(parent) || filteredChildren?.length) {
      filteredOptions.push({ ...parent, children: filteredChildren });
    }
  });
  return filteredOptions;
}

export const DepartmentFilter = memo(
  ({
    parentOptions,
    confirmedSelection,
    setConfirmedSelection,
  }: {
    parentOptions: DepartmentOption[];
    confirmedSelection: FilterSelection;
    setConfirmedSelection: (state: Set<React.Key>) => void;
  }) => {
    const filterStateText = GetFilterButtonCounter(confirmedSelection);

    const allOptionsFlat = useMemo(() => {
      return getAllOptionsFlat(parentOptions);
    }, [parentOptions]);
    const [
      filteredOptionsFlat,
      onSearch,
      clearSearch,
      searchHasFocus,
      setFocus,
    ] = useSearch<DepartmentOption>(allOptionsFlat);

    const filteredOptions = useMemo(
      () => getFilteredOptions(filteredOptionsFlat, parentOptions),
      [filteredOptionsFlat, parentOptions]
    );
    const [selected, onSelectionWithSelectAll] = useSelectedWithNestedSelectAll(
      filteredOptions,
      confirmedSelection
    );
    const onSelectionConfirm = () => {
      clearSearch();
      setConfirmedSelection(selected);
    };

    const { visibleOptions, onHandleScroll } =
      useInfiniteScroll<DepartmentOption>(filteredOptions);

    return (
      <FilterButtonWithMenu
        buttonLabel="部門"
        buttonText={filterStateText}
        showClearIcon={hasSelection(confirmedSelection)}
        onClearSelection={() => {
          setConfirmedSelection(new Set());
          onSelectionWithSelectAll(new Set());
        }}
        onSelectionConfirm={onSelectionConfirm}
      >
        <SearchField
          placeholderText="部門名、コード、検索キー"
          onChange={onSearch}
          setFocus={setFocus}
        />
        {filteredOptions.length > 0 ? (
          <FilterMenu
            // Important! This is preventing from losing focus on search field when results reappear
            autoFocus={!searchHasFocus}
            selectionMode="multiple"
            selectedKeys={selected}
            onSelectionChange={onSelectionWithSelectAll}
            onScroll={onHandleScroll}
          >
            <FilterItem id="all" removeBackgroundForSelected>
              <Text slot="label">{allOptionsSelected}</Text>
            </FilterItem>
            <Separator />
            {visibleOptions.map((option) => {
              return (
                <DepartmentWithChildren key={option.value} option={option} />
              );
            })}
          </FilterMenu>
        ) : (
          <NoOptionsText />
        )}
      </FilterButtonWithMenu>
    );
  }
);
