import { Selection } from "react-aria-components";
import React, { useCallback } from "react";
import { LocalFilterStateToConfirmedFilterState } from "./LocalToConfirmedState";
import { FilterSelection } from "./FilterSelection";

export interface Option {
  value: string;
  label: string;
}

export function listToSetSelection(selection: FilterSelection) {
  if (selection === "all") return "all";
  return new Set(selection);
}

export function hasSelection(selection: FilterSelection): boolean {
  return selection === "all" || selection.length > 0;
}

function sortOptions(selected: Selection, options: Option[]) {
  if (selected === "all") return "all";
  if (selected.size === 0) return [];

  // Sort selected options by order of options
  const copiedArray = [...selected];
  const flatOptions: React.Key[] = options.map((o) => o.value);
  copiedArray.sort((i, j) => {
    return flatOptions.indexOf(i) - flatOptions.indexOf(j);
  });
  return copiedArray;
}

// This hook provides state and callback function that we can use in Filter to set Confirmed State
// It ensures correct state if all options were selected and
// keeps selected options in order accordingly options order
export function useFilterConfirmedState(
  options: Option[],
  initialState?: FilterSelection
): [FilterSelection, (state: Set<React.Key>) => void] {
  const [confirmedState, setConfirmedState] = React.useState<FilterSelection>(
    initialState || []
  );

  const onSetConfirmedState: (state: Set<React.Key>) => void = useCallback(
    (newConfirmedState: Set<React.Key>) => {
      setConfirmedState(() => {
        // see FilterInterface.md for details
        const transformedState = LocalFilterStateToConfirmedFilterState(
          options.map((option) => option.value),
          newConfirmedState
        );
        return sortOptions(transformedState, options);
      });
    },
    [options, setConfirmedState]
  );

  return [confirmedState, onSetConfirmedState];
}
