export interface ItemWithSubItems {
  item_id: number;
  sub_items: SubItemsDetails[];
}
export interface SubItemsDetails {
  id: number;
  name: string;
  yomigana: string;
  romaji: string;
  code: string;
  name_for_search: string;
  excise_id: string;
  is_active: boolean;
}
export async function fetchSubItemsDetails(itemIDS: string[]) {
  if (itemIDS.length === 0) {
    return [];
  }
  const apiURL = `/api/sub_items?item_ids=${itemIDS.join(",")}`;
  return fetch(apiURL)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      return response.json() as Promise<ItemWithSubItems[]>;
    })
    .catch((error) => {
      throw error;
    });
}
