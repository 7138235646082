import React, { memo, useEffect, useRef, useState } from "react";
import "./ProjectTable.css";
import {
  FinancialStatementType,
  ProjectReportView,
} from "../../__generated__/graphql";
import { addCommas } from "./ProjectSummary";
import { BuildDrillDownUrl } from "./DrillDownBuilder";

export interface ReportTableProps {
  projectViews: ProjectReportView[];
  reportTableHeaders: string[];
  projectBiid: string;
  drillDownUrlParameter: string | null; // This is for Drill-Down url's parameters without Account ID, Sub-Account ID
}

function checkForBalanceSheetView(
  view: ProjectReportView,
  nextView: ProjectReportView
) {
  let nextRowIsBalanceSheet = false;
  if (
    view.financialStatementType !== FinancialStatementType.BalanceSheet &&
    nextView &&
    nextView.financialStatementType === FinancialStatementType.BalanceSheet
  ) {
    nextRowIsBalanceSheet = true;
  }
  return nextRowIsBalanceSheet;
}

function getClassesForBodyRow(
  view: ProjectReportView,
  nextView: ProjectReportView
) {
  const nextRowIsBalanceSheet = checkForBalanceSheetView(view, nextView);
  return [
    view.reportViewType.toLowerCase(),
    nextRowIsBalanceSheet && "next-is-balance-row",
    view.labelIndent === 0 && "top-level",
  ]
    .filter((v) => v)
    .join(" ");
}

export function getNumberOfMonths(headers: string[]) {
  // -3 is name, opening balance, total balance columns
  // number of months can differ depends on month picker
  return headers.length - 3;
}

const AddDrillDownLink = (
  view: ProjectReportView,
  projectBiid: string,
  drillDownUrlParameter: string | null,
  monthName?: string,
  balanceNumber?: string
): JSX.Element | string => {
  const { name } = view;
  const content = balanceNumber ?? name;

  // We don't make a Drill-Down link for the month that has ZERO balance
  if (balanceNumber === "0") {
    return "0";
  }

  const hrefUrl = BuildDrillDownUrl(
    view,
    projectBiid,
    drillDownUrlParameter,
    monthName
  );

  if (hrefUrl) {
    return (
      <a className="drill-down-url" href={hrefUrl}>
        {content}
      </a>
    );
  }

  return content;
};

interface ProjectViewNameProps {
  projectView: ProjectReportView;
  projectBiid: string;
  drillDownUrlParameter: string | null;
}

const ProjectViewNameToolTip = (
  props: ProjectViewNameProps
): JSX.Element | null => {
  const { projectView, projectBiid, drillDownUrlParameter } = props;

  const nameSpanRef = useRef<HTMLDivElement>(null);
  const toolTipSpanRef = useRef<HTMLDivElement>(null);

  // Check if the text is not omitted after the first rendering
  useEffect(() => {
    const nameSpanElement = nameSpanRef.current;
    if (!nameSpanElement) {
      return;
    }

    const toolTipSpanElement = toolTipSpanRef.current;
    if (!toolTipSpanElement) {
      return;
    }

    const { scrollHeight, clientHeight } = nameSpanElement;
    // hide the tool-tip in case of it is not a long project name
    if (scrollHeight <= clientHeight) {
      toolTipSpanElement.style.display = "none";
    }
  });

  return (
    <div className="report-view-name">
      <span
        ref={nameSpanRef}
        style={
          {
            "--indent": projectView.labelIndent,
          } as React.CSSProperties
        }
        className="name-column indent"
      >
        {AddDrillDownLink(projectView, projectBiid, drillDownUrlParameter)}
      </span>
      <span ref={toolTipSpanRef} className="account-tooltip-label">
        {projectView.name}
      </span>
    </div>
  );
};

// Use memo to prevent re-rendering of the rows
const ProjectRow = memo(
  ({
    classes,
    projectView,
    projectBiid,
    drillDownUrlParameter,
    reportTableHeaders,
  }: {
    classes: string;
    projectView: ProjectReportView;
    projectBiid: string;
    drillDownUrlParameter: string | null;
    reportTableHeaders: string[];
  }) => {
    return (
      <div className={`body-row ${classes}`}>
        <ProjectViewNameToolTip
          projectView={projectView}
          projectBiid={projectBiid}
          drillDownUrlParameter={drillDownUrlParameter}
        />
        <div className="value-column">
          {addCommas(projectView.monthlyBalanceRow?.openingValue || 0)}
        </div>
        {projectView.monthlyBalanceRow?.values.map((value, index) => {
          return (
            <div
              className="value-column "
              key={value.toString() + index.toString()}
            >
              {AddDrillDownLink(
                projectView,
                projectBiid,
                drillDownUrlParameter,
                reportTableHeaders[index + 2],
                addCommas(value)
              )}
            </div>
          );
        })}
        <div className="value-column">
          {AddDrillDownLink(
            projectView,
            projectBiid,
            drillDownUrlParameter,
            undefined,
            addCommas(projectView.monthlyBalanceRow?.totalValue || 0)
          )}
        </div>
      </div>
    );
  }
);

const ProjectTable = ({
  projectViews,
  reportTableHeaders,
  projectBiid,
  drillDownUrlParameter,
}: ReportTableProps) => {
  // We have a problem with rendering performance on low spec machines
  // It takes significant amount of time to render the table
  // Main problem is reactjs renderer itself
  // To resolve this issue we render the table in batches
  // That allow user to see the table faster
  // In future we may consider to use window library to render only visible rows
  // https://legacy.reactjs.org/docs/optimizing-performance.html#virtualize-long-lists
  const renderingBatch = 30;
  const [renderedItemsCount, setRenderedItemsCount] =
    useState<number>(renderingBatch);
  if (renderedItemsCount < projectViews.length) {
    // We use setTimeout to allow reactjs to render current batch
    setTimeout(() => {
      setRenderedItemsCount((currentPage) => {
        const nextBatch = currentPage + renderingBatch;
        return nextBatch < projectViews.length
          ? nextBatch
          : projectViews.length;
      });
    });
  }

  return (
    <div className="table-content">
      <div
        className="project-table"
        style={
          {
            "--number-of-months": getNumberOfMonths(reportTableHeaders),
            "--number-of-rows": projectViews.length,
          } as React.CSSProperties
        }
      >
        {projectViews.slice(0, renderedItemsCount).map((projectView, index) => {
          const classes = getClassesForBodyRow(
            projectView,
            projectViews[index + 1]
          );
          return (
            <ProjectRow
              key={`${projectBiid}_${projectView.reportViewType}_${
                projectView.objectID
              }_${projectView.parentID ?? 0}`}
              classes={classes}
              projectView={projectView}
              projectBiid={projectBiid}
              drillDownUrlParameter={drillDownUrlParameter}
              reportTableHeaders={reportTableHeaders}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProjectTable;
