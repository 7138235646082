import React, { useCallback, useEffect, useState } from "react";
import { Selection } from "react-aria-components";
import {
  checkSelectAllStatus,
  updateSelectedState,
} from "./useSelectedWithSelectAllLogic";
import { FilterSelection } from "../../ReportFilter/Helpers/FilterSelection";

interface HierarchyOptions {
  value: React.Key;
  children?: HierarchyOptions[];
}

export function getAllValuesFlat(options: HierarchyOptions[]) {
  const allChildOptions = options.map((o) => o.children || []).flat();
  return options.concat(allChildOptions).map((option) => option.value);
}

export const useSelectedWithNestedSelectAll = (
  values: HierarchyOptions[],
  initialSelection: FilterSelection
): [Set<React.Key>, (selection: Selection) => void] => {
  let SelectionSet: Set<any>;
  if (initialSelection === "all") {
    SelectionSet = new Set(getAllValuesFlat(values));
  } else {
    SelectionSet = new Set(initialSelection);
  }
  const [selected, setSelected] = useState<Set<React.Key>>(SelectionSet);

  const onSelectionChange: (nextSelection: Selection) => void = useCallback(
    (nextSelection: Selection) => {
      const allValuesFlat = getAllValuesFlat(values);
      const nextSelectedValues =
        nextSelection === "all" ? new Set(allValuesFlat) : nextSelection;
      setSelected((prevSelection) => {
        let result = new Set<React.Key>(nextSelectedValues);
        // We reuse the same logic for Select All from useSelectedWithSelectAllLogic for individual parent options
        // This is an advanced functional programming pattern, I'm proud of it
        // First we check parent options one by one if we need to select or deselect them
        values.forEach((option) => {
          if (!option.children || !option.children.length) return;

          result = updateSelectedState(
            result,
            prevSelection,
            option.children.map((o) => o.value),
            option.value
          );
        });
        // And after we checked all individual parent options we check Select All option state
        return updateSelectedState(result, prevSelection, allValuesFlat);
      });
    },
    [values]
  );

  // If options change Select All option may be in a wrong state
  // This function checks if Select All option should be selected or not
  useEffect(() => {
    const allValuesFlat = getAllValuesFlat(values);
    setSelected((currentState) => {
      let result = currentState;
      // We check individual parent options one by one if we need to select or deselect them
      values.forEach((option) => {
        if (!option.children || !option.children.length) return;

        result = checkSelectAllStatus(
          result,
          option.children.map((o) => o.value),
          option.value
        );
      });
      // And after we checked all individual parent options we check Select All option state
      return checkSelectAllStatus(result, allValuesFlat);
    });
  }, [values]);

  return [selected, onSelectionChange];
};
