import "./ErrorPage.scss";
import React from "react";
import ErrorIcon from "./images/error.png";

// in case we get an uncaught error, log it
export const logError = (error: Error, info: { componentStack: string }) => {
  console.error("Uncaught error:", error, info);
};
export const ErrorPage = () => (
  <div className="error-container">
    <div className="error-section">
      <div className="error-body">
        <div>
          <img alt="error icon" src={ErrorIcon} width="250px" height="200px" />
        </div>
        <div className="error-description">
          <p>
            システム上の何らかの問題により、一時的にページを表示できません。
          </p>
          <p>再度時間を置いてアクセスしてください。</p>
          <br />
          <p>
            しばらく待っても改善されない場合、
            <a href="/redirect_zendesk">
              マネーフォワード クラウドコンタクトセンター
            </a>
            までお問い合わせください。
          </p>
        </div>
      </div>
    </div>
  </div>
);
