import React from "react";
import { Separator, Text } from "react-aria-components";
import { useSelectedWithSelectAllLogic } from "../../CommonComponents/FilterButtonWithMenu/useSelectedWithSelectAllLogic";
import { FilterButtonWithMenu } from "../../CommonComponents/FilterButtonWithMenu/FilterButtonWithMenu";
import {
  FilterItem,
  FilterMenu,
} from "../../CommonComponents/FilterButtonWithMenu/FilterMenu";
import {
  allOptionsSelected,
  GetFilterButtonText,
} from "../../CommonComponents/FilterButtonWithMenu/GetFilterButtonText";
import { hasSelection, Option } from "../Helpers/useFilterConfirmedState";
import { FilterSelection } from "../Helpers/FilterSelection";

export const JournalTypeFilter = ({
  options,
  confirmedSelection,
  setConfirmedSelection,
}: {
  options: Option[];
  confirmedSelection: FilterSelection;
  setConfirmedSelection: (state: Set<React.Key>) => void;
}) => {
  const allValues: Set<React.Key> = new Set(
    options.map((option) => option.value)
  );
  const [selected, onSelectionWithSelectAll] = useSelectedWithSelectAllLogic(
    [...allValues],
    confirmedSelection
  );
  const filterStateText = GetFilterButtonText(confirmedSelection, options);

  const onSelectionConfirm = () => {
    setConfirmedSelection(selected);
  };

  return (
    <FilterButtonWithMenu
      buttonLabel="仕訳種別"
      buttonText={filterStateText}
      showClearIcon={hasSelection(confirmedSelection)}
      onClearSelection={() => {
        setConfirmedSelection(new Set());
        onSelectionWithSelectAll(new Set());
      }}
      onSelectionConfirm={onSelectionConfirm}
    >
      <FilterMenu
        selectionMode="multiple"
        selectedKeys={selected}
        onSelectionChange={onSelectionWithSelectAll}
      >
        <FilterItem id="all" removeBackgroundForSelected>
          <Text slot="label">{allOptionsSelected}</Text>
        </FilterItem>
        <Separator />
        {options.map((option) => {
          return (
            <FilterItem id={option.value} key={option.value}>
              <Text slot="label">{option.label}</Text>
            </FilterItem>
          );
        })}
      </FilterMenu>
    </FilterButtonWithMenu>
  );
};
