import React, { useCallback, useEffect, useState } from "react";
import { Selection } from "react-aria-components";
import { FilterSelection } from "../../ReportFilter/Helpers/FilterSelection";

export function checkIfAllOptionsSelected(
  values: React.Key[],
  selection: Set<React.Key>
) {
  // we check for values.length > 0 because we don't want to consider it as all options selected case
  return values.length > 0 && values.every((value) => selection.has(value));
}

export function addValues(nextSelection: Set<React.Key>, values: React.Key[]) {
  const newState = new Set(nextSelection);
  values.forEach((value) => newState.add(value));
  return newState;
}

export function removeValues(
  nextSelection: Set<React.Key>,
  values: React.Key[]
) {
  const newState = new Set(nextSelection);
  values.forEach((value) => newState.delete(value));
  return newState;
}

export function checkSelectAllStatus(
  nextSelection: Set<React.Key>,
  values: React.Key[],
  allOptionKey: React.Key = "all"
): Set<React.Key> {
  const allOptionsSelected = checkIfAllOptionsSelected(values, nextSelection);
  // if we selected all available options -> set state to "all"
  if (!nextSelection.has(allOptionKey) && allOptionsSelected) {
    return addValues(nextSelection, [allOptionKey]);
  }

  // if all options selected, and we deselected one option -> deselect "all" too
  if (nextSelection.has(allOptionKey) && !allOptionsSelected) {
    return removeValues(nextSelection, [allOptionKey]);
  }

  return nextSelection;
}

// Check what we need to do with Select All option and return updated state
export function updateSelectedState(
  nextSelectedValues: Set<React.Key>,
  prevSelection: Set<React.Key>,
  values: React.Key[],
  allOptionKey: React.Key = "all"
) {
  // Select All option selected
  if (
    nextSelectedValues.has(allOptionKey) &&
    !prevSelection.has(allOptionKey)
  ) {
    return addValues(nextSelectedValues, values);
  }
  // Select All option deselected
  if (
    !nextSelectedValues.has(allOptionKey) &&
    prevSelection.has(allOptionKey)
  ) {
    return removeValues(nextSelectedValues, values);
  }

  return checkSelectAllStatus(nextSelectedValues, values, allOptionKey);
}

// Hook for select all / deselect all logic
// It keeps select all option in sync with all options
export const useSelectedWithSelectAllLogic = (
  values: React.Key[],
  initialSelection: FilterSelection
): [Set<React.Key>, (selection: Selection) => void] => {
  let SelectionSet: Set<any>;
  // set all selection if initial URL param is "all"
  if (initialSelection === "all") {
    SelectionSet = new Set(values);
  } else {
    SelectionSet = new Set(initialSelection);
  }
  const [selected, setSelected] = useState<Set<React.Key>>(SelectionSet);

  const onSelectionChange: (nextSelection: Selection) => void = useCallback(
    (nextSelection: Selection) => {
      const nextSelectedValues =
        nextSelection === "all" ? new Set(values) : nextSelection;
      setSelected((prevSelection) => {
        return updateSelectedState(nextSelectedValues, prevSelection, values);
      });
    },
    [values]
  );
  // If options change Select All option may be in a wrong state
  // This function checks if Select All option should be selected or not
  useEffect(() => {
    setSelected((currentState) => {
      return checkSelectAllStatus(currentState, values);
    });
  }, [values]);

  return [selected, onSelectionChange];
};
