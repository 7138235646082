import { gql } from "@apollo/client";
import { JournalType, JournalStatus } from "../__generated__/graphql";

const GET_PROJECT = gql(/* GraphQL */ `
  query GetProjectDetails(
    $startDate: String
    $endDate: String
    $journalTypes: [JournalType!]
    $projectBIIDs: [String!]
    $journalStatuses: [JournalStatus!]
    $departments: [String!]
    $businessPartners: [String!]
    $itemIDs: [String!]
    $subItemIDs: [String!]
    $taxFilter: String
    $showSubItems: String
    $page: Int
    $perPage: Int
  ) {
    project(
      startDate: $startDate
      endDate: $endDate
      journalTypes: $journalTypes
      projectBIIDs: $projectBIIDs
      journalStatuses: $journalStatuses
      departments: $departments
      businessPartners: $businessPartners
      itemIDs: $itemIDs
      subItemIDs: $subItemIDs
      taxFilter: $taxFilter
      showSubItems: $showSubItems
      page: $page
      perPage: $perPage
    ) {
      projects {
        biid
        name
        code
        sales
        costOfGoodsSold
        grossProfit
        grossProfitRateString
        operatingProfit
        operatingProfitRateString
        reportTableHeaders
        projectViews {
          name
          reportViewType
          financialStatementType
          objectID
          identificationCode
          displayOrder
          labelIndent
          parentID
          monthlyBalanceRow {
            openingValue
            values
            totalValue
          }
        }
      }
      totalProjects
    }
  }
`);

export default GET_PROJECT;
