import { FilterSelection } from "../../ReportFilter/Helpers/FilterSelection";

interface Option {
  value: string;
  label: string;
}

export const allOptionsSelected = "すべて";

export function GetFilterButtonText(
  selectedOptions: FilterSelection,
  options: Option[]
) {
  if (selectedOptions === "all") {
    return allOptionsSelected;
  }
  return selectedOptions
    .map((value) => {
      return options.find((option) => option.value === value)?.label;
    })
    .join(", ");
}

export function GetFilterButtonCounter(selectedOptions: FilterSelection) {
  if (selectedOptions === "all") {
    return allOptionsSelected;
  }
  if (selectedOptions.length === 0) {
    return "";
  }
  return `${selectedOptions.length}件`;
}
