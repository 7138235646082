import { Pagination as AntdPagination } from "antd";
import "./Pagination.css";
import { addCommas } from "../../ReportTable/ProjectSummary";

export interface PaginationProps {
  totalProjectNumber: number;
  onPageChange: (page: number) => void;
  currentPage: number;
}

const numberOfProjectPerPage = 50;
// prepareTotalProjectForPagination: for the library AntdPagination, we need to keep the totalProject >= perPage to avoid
// side effect of the library
const prepareTotalProjectForPagination = (totalProject: number | undefined) => {
  if (totalProject && totalProject >= numberOfProjectPerPage) {
    return totalProject;
  }
  // We want to show 1 / 1 if we can't fill one page
  return numberOfProjectPerPage;
};

const Pagination = ({
  totalProjectNumber,
  onPageChange,
  currentPage,
}: PaginationProps) => {
  // TODO: CAMIDREP-613 Customize antd pagination to Our Figma's design
  return (
    <div className="pagination-container">
      <AntdPagination
        current={currentPage}
        simple
        total={prepareTotalProjectForPagination(totalProjectNumber)}
        pageSize={numberOfProjectPerPage} // Fixed page size = 50
        onChange={(page: number) => onPageChange(page)}
      />
      <span className="total-project">
        （{addCommas(totalProjectNumber)}件）
      </span>
    </div>
  );
};

export default Pagination;
