import React, { useEffect, useState } from "react";
import { Selection, Text } from "react-aria-components";
import ReactAreaModalComponent from "../../CommonComponents/ModalComponent/ReactAreaModal";
import { FilterFooter } from "../../CommonComponents/FilterButtonWithMenu/FilterFooter";
import {
  DefaultJournalStatusMenuItem,
  DefaultJournalStatusMenu,
  DefaultJournalStatusClearIcon,
} from "./JournalStatusUtilities";
import { Option } from "../Helpers/useFilterConfirmedState";
import {
  putDefaultJournalStatusToDb,
  useFetchDefaultJournalStatus,
} from "./useDefaultJournalStatusFetchAndPut";
import "./DefaultJournalStatus.scss";

export const DefaultJournalStatus = ({
  setModalStatus,
  journalStatusOptions,
  onDefaultChange,
}: {
  setModalStatus: (value: boolean) => void;
  journalStatusOptions: Option[];
  onDefaultChange: (defaultSettings: Set<React.Key>) => void;
}) => {
  const initialDefaultSettings = useFetchDefaultJournalStatus();
  const [selected, setSelection] = useState<Selection>(new Set());
  useEffect(() => {
    setSelection(initialDefaultSettings);
  }, [initialDefaultSettings]);

  const handleOnSubmitClick = () => {
    putDefaultJournalStatusToDb(
      new Set(selected),
      () => {
        onDefaultChange(new Set(selected));
      },
      () => {
        setSelection(initialDefaultSettings);
      }
    );
    setModalStatus(false);
  };

  return (
    <div className="default-journal-status-container">
      <div className="default-journal-status-header">
        <h2>承認ステータスのデフォルト設定</h2>
        <DefaultJournalStatusClearIcon
          onPointerDown={() => setModalStatus(false)}
        />
      </div>
      <div className="default-journal-status-options">
        <DefaultJournalStatusMenu
          selectionMode="multiple"
          selectedKeys={selected}
          onSelectionChange={setSelection}
        >
          {journalStatusOptions.map((option) => {
            return (
              <DefaultJournalStatusMenuItem
                id={option.value}
                key={option.value}
              >
                <Text slot="label">{option.label}</Text>
              </DefaultJournalStatusMenuItem>
            );
          })}
        </DefaultJournalStatusMenu>
      </div>
      <FilterFooter
        onOK={handleOnSubmitClick}
        onCancel={() => setModalStatus(false)}
      />
    </div>
  );
};
interface ModalBindingProps {
  modalStatus: boolean;
  setModalStatus: (value: boolean) => void;
  journalStatusOptions: Option[];
  onDefaultChange: (defaultSettings: Set<React.Key>) => void;
}
export const DefaultJournalStatusModalBindingComponent = ({
  modalStatus,
  setModalStatus,
  journalStatusOptions,
  onDefaultChange,
}: ModalBindingProps): JSX.Element => {
  return (
    <>
      <button
        type="button"
        className="default-journal-status-filter"
        onClick={() => {
          setModalStatus(true);
        }}
      >
        <p>デフォルト設定</p>
      </button>
      <ReactAreaModalComponent
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
      >
        <DefaultJournalStatus
          setModalStatus={setModalStatus}
          journalStatusOptions={journalStatusOptions}
          onDefaultChange={onDefaultChange}
        />
      </ReactAreaModalComponent>
    </>
  );
};
