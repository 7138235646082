import type { CheckboxProps } from "react-aria-components";
import { Checkbox } from "react-aria-components";
import "./FilterCheckbox.scss";

export const FilterCheckbox = ({
  children,
  isSelected,
  ...props
}: CheckboxProps) => {
  return (
    <Checkbox {...props} isSelected={isSelected}>
      <>
        <div className="checkbox" />
        {children}
      </>
    </Checkbox>
  );
};
