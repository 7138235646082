import React from "react";
import "./ClearIconButton.scss";

export const ClearIconButton = ({
  onPointerDown,
}: {
  onPointerDown: (event: React.UIEvent) => void;
}) => {
  return (
    // For this element I used div instead of button, because we sometimes use it inside button
    // And button inside button is not a recomended practice
    <div
      tabIndex={-1}
      role="button"
      // we need this event to fix linter issue,
      // that says that custom interactive elements must have onkey handler
      onKeyDown={onPointerDown}
      // this handler is triggered then user press on the icon
      onPointerDown={onPointerDown}
      className="clear-icon"
      data-testid="clear-icon"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
      </svg>
    </div>
  );
};
