import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";

const environment: string = process.env.REACT_APP_ENVIRONMENT!;
// Initiating a new instance of apollo-client
const graphQLClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  uri:
    environment === "development"
      ? process.env.REACT_APP_GRAPHQL_SERVER_DEV
      : process.env.REACT_APP_GRAPHQL_SERVER_PROD,
  cache: new InMemoryCache(),
});

export { graphQLClient };
