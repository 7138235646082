import { useCallback, useEffect, useState } from "react";

export interface Option {
  value: string;
  label: string;
  name_for_search: string;
  identification_code: string;
}

const optionHasSearchQuery = (searchQuery: string, option: Option): boolean => {
  const fieldsToCheck = [
    option.label,
    option.name_for_search,
    option.identification_code,
  ];
  // If any of field has searchQuery we return true
  return fieldsToCheck.some((field: string) => {
    return field && field.toLowerCase().includes(searchQuery.toLowerCase());
  });
};

export const useSearch = <T extends Option>(
  originalOptions: T[]
): [
  T[],
  (searchQuery: string) => void,
  () => void,
  boolean,
  (hasFocus: boolean) => void
] => {
  const [filteredOptions, setFilteredOptions] = useState<T[]>(originalOptions);
  // Some elements may grab focus automatically,
  // this state allows us to set up attribute autoFocus=!searchHasFocus
  const [searchHasFocus, setSearchHasFocus] = useState<boolean>(false);

  // React ignores initial state after first render, so we need to set it again if options have changed
  useEffect(() => {
    setFilteredOptions(originalOptions);
  }, [originalOptions]);

  const onSearch = useCallback(
    (searchQuery: string) => {
      const trimmedSearch = searchQuery.trim();
      if (trimmedSearch.length === 0) {
        setFilteredOptions(originalOptions);
      }

      const filtered = originalOptions.filter((option) => {
        return optionHasSearchQuery(trimmedSearch, option);
      });
      setFilteredOptions(filtered);
    },
    [originalOptions]
  );

  const clearSearch = () => onSearch("");

  return [
    filteredOptions,
    onSearch,
    clearSearch,
    searchHasFocus,
    setSearchHasFocus,
  ];
};
