import { format } from "date-fns";
import React, { useContext } from "react";
import { ProjectReportView, ReportViewType } from "../../__generated__/graphql";
import WarningIcon from "./icons/WarningIcon.png";
import ExternalIcon from "./icons/ExternalIcon.png";
import { AllDepartmentCommonValue, Department } from "../../api/getDepartment";
import { State } from "../../useProjectReportReducer";
import { BusinessPartner } from "../../api/getBusinessPartner";
import { DepartmentOption } from "../ReportFilter/DepartmentFilter/DepartmentFilter";
import { MasterDataContext } from "../../FilterContext";
import { useDepartmentOptions } from "../ReportFilter/DepartmentFilter/useDepartmentOptions";
import { useBusinessPartnerOptions } from "../ReportFilter/BusinessPartner/useBusinessPartnerOptions";
import { useConvertTermDetailToTermInfo } from "../ReportFilter/MonthFilter/useConvertTermDetailToTermInfo";

// for the 補助科目なし sub-item
const ItemWithoutSubItemsID = -1;
const DrillDownGuideLink =
  "https://biz.moneyforward.com/support/ac-plus/guide/books2/projects-report.html";

interface SelectedDepartmentsInfo {
  isSingleSelectionType: boolean;
  encryptedID: string;
  range: string;
}

// This function returns a flat array with value and encryptedID
// from parent and children of SelectOptions[]
export const FlattenSelectOptions = (
  options: DepartmentOption[]
): { value: string; encrypted_id?: string }[] => {
  return options
    .map((option) => {
      const result: { value: string; encrypted_id?: string }[] = [
        { value: option.value, encrypted_id: option.encrypted_id },
      ];

      if (option.children) {
        option.children.forEach((childOption) => {
          result.push({
            value: childOption.value,
            encrypted_id: childOption.encrypted_id,
          });
        });
      }

      return result;
    })
    .flat();
};

// To check whether a selection is only a parent and its own child
// return value of parent ("id_all") if match the condition
// if not, return ""
export const SelectedSingleParentChecker = (
  selectedDepts: string[],
  deptsOptions: DepartmentOption[]
): string => {
  const parentValue = selectedDepts.find((deptValue) =>
    deptValue.endsWith("_all")
  );

  const count = selectedDepts.filter((deptValue) =>
    deptValue.endsWith("_all")
  ).length;

  if (parentValue && count === 1) {
    const parentDept = deptsOptions.find(
      (option) => option.value === parentValue
    );
    if (
      parentDept?.children &&
      parentDept.children.length + 1 === selectedDepts.length
    ) {
      return parentValue;
    }
  }
  return "";
};

/*
  A Single Selection Type is one of the below cases:
   1. Select All : すべて
   2. Without Any Dept : 全部門(共通)
   3. A Parent Dept: 部門01（合計）
   4. Without Any Children Dept Inside A Specific Dept: 部門01（共通）
   5. A Child Dept Of A Specific Dept: 子部門1.1
   6. A Dept That Has No Child: 部門02
  */
export const GetSelectedDepartmentsInfo = (
  selectedDepartments: string[],
  departmentsOptions: DepartmentOption[]
): SelectedDepartmentsInfo => {
  const flattenOptions = FlattenSelectOptions(departmentsOptions);
  // for case 1
  // the selectedDepartments is only []
  if (selectedDepartments.length === 0) {
    // finding by "without" value to get the option that includes encrypted ZeroId
    const zeroIdOption = flattenOptions.find(
      (option) => option.value === AllDepartmentCommonValue
    );
    if (zeroIdOption && zeroIdOption.encrypted_id) {
      return {
        isSingleSelectionType: true,
        encryptedID: zeroIdOption.encrypted_id,
        range: "0",
      };
    }
  }

  // for cases of 2,4,5,6
  // the selectedDepartments is something like ['1']
  if (selectedDepartments.length === 1) {
    const selectedOption = flattenOptions.find(
      (option) => option.value === selectedDepartments[0]
    );
    if (selectedOption && selectedOption.encrypted_id) {
      return {
        isSingleSelectionType: true,
        encryptedID: selectedOption.encrypted_id,
        range: "1",
      };
    }
  }

  // For case 3: Selected A Parent Dept
  // the selectedDepartments is something like: ['1_all', '1_only', '2', '3'] '2', '3' are children Dept of Dept 1
  const selectedParentValue = SelectedSingleParentChecker(
    selectedDepartments,
    departmentsOptions
  );
  const selectedParent = flattenOptions.find(
    (option) => option.value === selectedParentValue
  );
  if (selectedParent && selectedParent.encrypted_id) {
    return {
      isSingleSelectionType: true,
      encryptedID: selectedParent.encrypted_id,
      range: "0",
    };
  }

  return {
    isSingleSelectionType: false,
    encryptedID: "",
    range: "",
  };
};

export const BuildDrillDownParameters = (
  state: State,
  departments: Department[],
  businessPartners: BusinessPartner[]
): string | null => {
  const { filterState } = state;
  const departmentOptions = useDepartmentOptions(departments);
  const businessPartnersOptions = useBusinessPartnerOptions(businessPartners);
  if (
    departmentOptions?.length === 0 ||
    businessPartnersOptions?.length === 0
  ) {
    return null;
  }
  let queryParams = "";
  // check for selected Business Partner
  // if selected multiple options then Drill-Down feature is disabled
  if (filterState.businessPartners.length > 1) {
    return null;
  }

  // DEPARTMENT PARAM
  const selectedDeptsInfo = GetSelectedDepartmentsInfo(
    filterState.departments,
    departmentOptions
  );
  // check for selected Department
  // if selected multiple options then Drill-Down feature is disabled
  if (!selectedDeptsInfo.isSingleSelectionType) {
    return null;
  }
  queryParams += `&dept=${selectedDeptsInfo.range},${selectedDeptsInfo.encryptedID}`;

  // BUSINESS PARTNER PARAM
  if (filterState.businessPartners.length === 1) {
    const foundOption = businessPartnersOptions.find(
      (option) => option.value === filterState.businessPartners[0]
    );
    if (foundOption && foundOption.encrypted_id) {
      queryParams += `&business_partner_id=${foundOption.encrypted_id}`;
    }
  }
  // TIME RANGE PARAM
  if (filterState.startDate && filterState.endDate) {
    const recognizedAtFrom = format(new Date(filterState.startDate), "MM/dd");
    const recognizedAtTo = format(new Date(filterState.endDate), "MM/dd");

    queryParams += `&recognized_at_from=${recognizedAtFrom}&recognized_at_to=${recognizedAtTo}`;
  }

  // JOURNAL TYPE PARAM
  if (filterState.journalTypes.length === 1) {
    if (filterState.journalTypes[0] === "1") {
      queryParams += `&is_closing=false`;
    } else {
      queryParams += `&is_closing=true`;
    }
  }

  // TAX PARAM
  if (filterState.taxFilter === "tax_exclude") {
    queryParams += `&exclude_excise=true`;
  } else {
    queryParams += `&exclude_excise=false`;
  }

  // JOURNAL STATUSES PARAM
  if (filterState.journalStatuses.length === 0) {
    queryParams += `&statuses[]=1&statuses[]=2&statuses[]=3&statuses[]=4`;
  } else {
    const journalStatusQuery = filterState.journalStatuses
      .map((status) => `&statuses[]=${status}`)
      .join("");
    queryParams += `${journalStatusQuery}`;
  }
  return queryParams;
};

/*
Update the date value in queryParameter according to the monthName
Input:
- queryParameters: "biid=123&&recognized_at_from=2023/2/1&recognized_at_to=2023/8/31&is_closing=true"
- monthName: "3月"
Output:
- ExchangeDate: ""biid=123&&recognized_at_from=2023/3/1&recognized_at_to=2023/3/31&is_closing=true"
*/
const ExchangeDate = (queryParameters: string, monthName: string): string => {
  const { masterData } = useContext(MasterDataContext);
  const termInfo = useConvertTermDetailToTermInfo(masterData.termDetails);
  const monthsInfo = termInfo?.monthsInfo;
  const monthNumber = monthName.replace(/月/g, "");
  if (monthsInfo && monthsInfo[monthNumber]) {
    let modifiedString = queryParameters.replace(
      /&recognized_at_from=[^&]*&recognized_at_to=[^&]*/,
      ""
    );
    const monthInfo = monthsInfo[monthNumber];
    const recognizedAtFrom = format(new Date(monthInfo.startDate), "MM/dd");
    const recognizedAtTo = format(new Date(monthInfo.endDate), "MM/dd");

    modifiedString += `&recognized_at_from=${recognizedAtFrom}&recognized_at_to=${recognizedAtTo}`;
    return modifiedString;
  }

  return queryParameters;
};

export const BuildDrillDownUrl = (
  view: ProjectReportView,
  projectBiid: string,
  queryParameters: string | null,
  monthName?: string
): string | null => {
  if (!queryParameters) {
    return null;
  }

  // If monthName (ex "3月") is passed here, it means we will build the drill-down url
  // for the balance of that month. So changing the date value in the query parameter is needed
  const queryParametersForBuild = monthName
    ? ExchangeDate(queryParameters, monthName)
    : queryParameters;

  const generalLedgePath = "/books/general_ledger";
  const subsidiaryLedgePath = "/books/subsidiary_ledger";
  const { reportViewType, objectID, parentID } = view;

  // For the case of item (ex: 現金, 未払金,...)
  if (reportViewType === ReportViewType.Item) {
    return `${generalLedgePath}?${queryParametersForBuild}&project_biid=${projectBiid}&own_side_item_id=${objectID}`;
  }

  // For the case of item without sub-item (補助科目なし)
  // 補助科目なし Sub-Item does not exist in Database
  // So the Backend response objectID -1 for this kind of Sub-Item
  if (
    reportViewType === ReportViewType.SubItem &&
    objectID === ItemWithoutSubItemsID &&
    parentID
  ) {
    return `${subsidiaryLedgePath}?${queryParametersForBuild}&project_biid=${projectBiid}&own_side_item_id=${parentID}`;
  }

  // For the case of sub-item (ex: 小口現金)
  if (reportViewType === ReportViewType.SubItem && parentID) {
    return `${subsidiaryLedgePath}?${queryParametersForBuild}&project_biid=${projectBiid}&own_side_item_id=${parentID}&own_side_sub_item_id=${objectID}`;
  }

  return null;
};

export const DrillDownNoticeMessage = (): JSX.Element => {
  return (
    <div className="drill-down-notice">
      <img className="drill-down-notice-icon" src={WarningIcon} alt="" />
      <div className="drill-down-notice-text">
        この検索条件ではプロジェクト別集計表から総勘定元帳へ遷移することはできません。詳しくは
      </div>
      <a className="drill-down-guide-link" href={DrillDownGuideLink}>
        <div className="drill-down-notice-text">使い方ガイド</div>
        <img className="external-icon" src={ExternalIcon} alt="" />
      </a>
      <div className="drill-down-notice-text">をご覧ください。</div>
    </div>
  );
};
