import "./LoadingSpinner.scss";
import { Lottie } from "./Lottie";
import AnimationData from "./src/animationData.json";

type Props = {
  /**
   *  Name of spinner design
   *
   *  @default 'primary'
   */
  variant?: "primary" | "secondary" | "subtle";
  /**
   * Size of spinner
   *
   * @default medium
   */
  size?: "xSmall" | "small" | "medium" | "large" | "xLarge";
};

const SizeMap: Record<NonNullable<Props["size"]>, string> = {
  xSmall: "size-x-small",
  small: "size-small",
  medium: "size-medium",
  large: "size-large",
  xLarge: "size-x-large",
};

/**
 * A component that displays LoadingSpinner
 */
export const LoadingSpinner = ({
  size = "medium",
  variant = "primary",
}: Props) => {
  const className = `loader ${SizeMap[size]} variant-${variant}`;

  return (
    <div className={className}>
      <Lottie animationData={AnimationData} />
    </div>
  );
};
