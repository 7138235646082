import React, { SetStateAction, useCallback } from "react";
import { format } from "date-fns";
import { FilterButtonWithMenu } from "../../CommonComponents/FilterButtonWithMenu/FilterButtonWithMenu";
import MonthPicker, { TermInfo } from "../MonthPicker/MonthPicker";
import { hasSelection } from "../Helpers/useFilterConfirmedState";

const getFilterStateText = (currentSelectedOption: string[]): string => {
  if (currentSelectedOption.length === 0) {
    return "すべて";
  }
  const formattedDates = currentSelectedOption.map((option) => {
    return format(new Date(option), "yyyy/MM");
  });

  return `${formattedDates[0]}-${formattedDates[1]}`;
};

// handleSelectedMonths extracts start date and end date
// based on months_info indexes get from handleMonthClick
const handleSelectedMonth = (
  termInfo: TermInfo,
  selectedMonthInfoIndices: string[]
): string[] => {
  // if user don't choose anything, return initialState([])
  if (!selectedMonthInfoIndices.length) {
    return [];
  }
  const startedMonth = termInfo.monthsInfo[selectedMonthInfoIndices[0]];
  const endDate =
    selectedMonthInfoIndices.length > 1
      ? termInfo.monthsInfo[selectedMonthInfoIndices[1]].endDate
      : startedMonth.endDate;

  return [startedMonth.startDate, endDate];
};

export const MonthFilter = ({
  termInfo,
  confirmedSelection,
  setConfirmedSelection,
}: {
  termInfo: TermInfo;
  confirmedSelection: string[];
  setConfirmedSelection: (state: SetStateAction<string[]>) => void;
}) => {
  // selectedMonths is the array of months in string format to handle MonthPicker
  const [selectedMonths, setSelectedMonths] = React.useState<string[]>([]);
  // selectedMonthInfoIndices is the array of months info indexes to handle start date and end date in query
  // TODO: after refactor MonthPicker, we can remove this state
  const [selectedMonthInfoIndices, setSelectedMonthInfoIndices] =
    React.useState<string[]>([]);

  // handleMonthClick returns indices of chosen months based on term_months
  const handleMonthClick = useCallback(
    (month: string) => {
      setSelectedMonths((prevMonthsDate) => {
        const prevMonths = prevMonthsDate.map((date) => {
          return (new Date(date).getMonth() + 1).toString();
        });
        const termInfoCopy = { ...termInfo };
        if (prevMonths.length === 1) {
          const { startYearTermMonths } = termInfoCopy;
          const orderOfMonth = startYearTermMonths.concat(
            termInfoCopy.endYearTermMonths
          );
          const prevMonthIndex = orderOfMonth.indexOf(prevMonths[0]);
          const nextMonthIndex = orderOfMonth.indexOf(month);

          if (nextMonthIndex < prevMonthIndex) {
            setSelectedMonthInfoIndices([month]);
            return [termInfoCopy.monthsInfo[month].startDate];
          }
          setSelectedMonthInfoIndices([prevMonths[0], month]);
          return [
            termInfoCopy.monthsInfo[prevMonths[0]].startDate,
            termInfoCopy.monthsInfo[month].endDate,
          ];
        }
        setSelectedMonthInfoIndices([month]);
        return [termInfoCopy.monthsInfo[month]?.startDate];
      });
    },
    [setSelectedMonths, termInfo]
  );
  const filterStateText = getFilterStateText(confirmedSelection);

  return (
    <FilterButtonWithMenu
      selectedKeys={[]}
      buttonLabel="期間"
      onSelectionConfirm={() => {
        setConfirmedSelection(
          handleSelectedMonth(termInfo, selectedMonthInfoIndices)
        );
      }}
      buttonText={filterStateText}
      showClearIcon={hasSelection(confirmedSelection)}
      onClearSelection={() => {
        setConfirmedSelection([]);
        setSelectedMonths([]);
        setSelectedMonthInfoIndices([]);
      }}
    >
      <MonthPicker
        termInfo={termInfo}
        handleMonthClick={handleMonthClick}
        selectedDate={selectedMonths}
      />
    </FilterButtonWithMenu>
  );
};
