// url params names
export const START_DATE = `start_date`;
export const END_DATE = `end_date`;
export const JOURNAL_TYPES = `journal_types`;
export const JOURNAL_STATUSES = `journal_statuses`;
export const DEPARTMENTS = `departments`;
export const BUSINESS_PARTNERS = `business_partners`;
export const TAX_FILTER = `tax_filter`;
export const ITEMS = `item_ids`;
export const SUB_ITEMS = `sub_item_ids`;
export const PROJECTS = `projects`;
export const DISPLAY_SUB_ITEM_OPTIONS = `show_sub_items`;

// this value is based on this reddit post:
// https://stackoverflow.com/a/417184
export const MAX_URL_LENGTH = 8000;
