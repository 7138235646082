// Download file function
// https://pqina.nl/blog/how-to-prompt-the-user-to-download-a-file-instead-of-navigating-to-it/
function downloadFileURL(file: Blob, filename: string) {
  // Create a link and set the URL using `createObjectURL`
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = URL.createObjectURL(file);
  link.download = filename;

  // It needs to be added to the DOM, so it can be clicked
  document.body.appendChild(link);
  link.click();

  // To make this work on Firefox we need to wait
  // a little while before removing it.
  setTimeout(() => {
    const { parentNode } = link;
    if (parentNode) {
      parentNode.removeChild(link);
    }
  }, 0);
}

function extractFileName(contentDisposition: string) {
  // This code may be produced by chatGPT, but I won't tell you
  let filename = "";
  if (contentDisposition) {
    const match = contentDisposition.match(/filename=(.+)/);
    if (match && match[1]) {
      filename = decodeURIComponent(match[1].trim());
    }
  }
  // File name can have "", browser will replace it with _, to prevent it lets remove it
  filename = filename.replaceAll('"', "");
  return filename;
}

export enum DownloadStatus {
  Success = "SUCCESS",
  AuthenticationError = "AUTHENTICATION_ERROR",
  ServerError = "SERVER_ERROR",
}

export interface DownloadResponse {
  status: DownloadStatus;
  response: Response;
}

async function fetchAndDownloadFile(
  fileURL: string,
  backUpFileName: string,
  requestParams?: Record<string, any>
): Promise<DownloadResponse> {
  // I see 2 ways to download file with js:
  // 1. add link with link.href=file_url
  // 2. download file content with fetch and do link.href = URL.createObjectURL(resp.blob)
  // We are using option 2. because it allows us to process error from the server.
  // But if we will have problems with performance we may review option #1.
  // I wrote more detailed explanation here: https://moneyforward.slack.com/archives/C0489KXMM51/p1678062936428659
  const body = JSON.stringify(requestParams);
  // we moved to POST request because it doesn't have limit for parameters size
  // with GET request we can hit limit for parameters size
  const response = await fetch(fileURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });

  // we expect those responses:
  // 200 is ok response from server
  // 304 is cached response
  // 302 is redirect to login page
  // I'm not sure about redirect here:
  // it is common technique, and we may use it in the future for redirecting to csv report file.
  // alternatively we may also check response.url === /session/new
  // or we may remove redirect for this API from ca-mid-web proxy
  if (!response.ok) {
    return { status: DownloadStatus.ServerError, response };
  }
  if (response.redirected) {
    return { status: DownloadStatus.AuthenticationError, response };
  }
  const contentDisposition = response.headers.get("Content-Disposition");
  let filename = "";
  if (contentDisposition) {
    filename = extractFileName(contentDisposition);
  } else {
    // in case if we couldn't receive Content-Disposition header
    filename = backUpFileName;
    // TODO: this should not be happening, log warning
    console.warn("No Content-Disposition header.");
  }
  const data = await response.blob();
  downloadFileURL(data, filename);
  return { status: DownloadStatus.Success, response };
}

export { fetchAndDownloadFile, downloadFileURL };
