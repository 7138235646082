import { format } from "date-fns";
import { useMemo } from "react";
import { TermDetails } from "../../../api/getTermDetails";
import { MonthsInfo, TermInfo } from "../MonthPicker/MonthPicker";

const DATE_FORMAT = "yyyy-MM-dd";
const emptyTermInfo: TermInfo = {
  startYear: "",
  endYear: "",
  startYearTermMonths: [],
  endYearTermMonths: [],
  monthsInfo: {},
  endDateOfTheTerm: "",
};

export const useConvertTermDetailToTermInfo = (
  data?: TermDetails
): TermInfo => {
  return useMemo(() => {
    if (!data || data.term_months.length === 0) {
      return emptyTermInfo;
    }
    const startYear: string = new Date(data.start_date)
      .getFullYear()
      .toString();
    const endYear: string = new Date(data.end_date).getFullYear().toString();

    const startYearTermMonths: string[] = [];
    const endYearTermMonths: string[] = [];
    const monthsInfo: MonthsInfo = {};
    const endDateOfTheTerm = data.end_date;

    data.term_months.forEach((term_month) => {
      const year: string = new Date(term_month.start_date)
        .getFullYear()
        .toString();
      const month: string = (
        new Date(term_month.start_date).getMonth() + 1
      ).toString();
      const monthStartDate = new Date(term_month.start_date);
      const monthEndDate = new Date(term_month.end_date);

      if (year === startYear) {
        startYearTermMonths.push(month);
      }
      if (startYear !== endYear && year === endYear) {
        endYearTermMonths.push(month);
      }

      monthsInfo[month] = {
        startDate: format(monthStartDate, DATE_FORMAT),
        endDate: format(monthEndDate, DATE_FORMAT),
      };
    });

    return {
      startYear,
      endYear,
      startYearTermMonths,
      endYearTermMonths,
      monthsInfo,
      endDateOfTheTerm,
    };
  }, [data]);
};
