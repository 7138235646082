import { useEffect, useRef } from "react";
import lottie from "lottie-web";

type Props = {
  /**
   * Animation json data created by lottie.
   *
   * @example
   *
   * ```
   * import primary from './primary.json';
   *
   * <Lottie animationData={primary} />
   * ```
   */
  animationData: any;
  /**
   * Whether to loop the animation or not.
   *
   * @default true
   */
  loop?: boolean;
};

/**
 * A component that displays Lottie file.
 */
export const Lottie = ({ animationData, loop = true }: Props) => {
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const animationItem = lottie.loadAnimation({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      animationData,
      container: ref.current,
      loop,
    });

    // eslint-disable-next-line consistent-return
    return () => {
      animationItem.destroy();
    };
  }, [animationData, loop, ref]);

  return <span ref={ref} />;
};
