import React, { forwardRef, useEffect, useRef, useState } from "react";
import CloseSummaryIcon from "./icons/DisclosureBasicCollapsed.png";
import OpenSummaryIcon from "./icons/DisclosureBasicNonCollapsed.png";
import "./ProjectSummary.css";
import { Project } from "../../__generated__/graphql";

interface ProjectNameProps {
  text: string;
  setIsToolTipVisible: (isVisible: boolean) => void;
}

// ProjectNameTooltip is a component that displays the project name and code with a tooltip.
// If the project name is not log(not omitted), then disable the tooltip.
const ProjectNameTooltip = (
  propsName: ProjectNameProps
): JSX.Element | null => {
  const { text, setIsToolTipVisible } = propsName;

  const textDivRef = useRef<HTMLDivElement>(null);
  const textSpanRef = useRef<HTMLDivElement>(null);
  const parentDivRef = useRef<HTMLDivElement>(null);

  // Check if the text is not omitted after the first rendering
  useEffect(() => {
    const divElement = textDivRef.current;
    if (!divElement) {
      return;
    }

    const spanElement = textSpanRef.current;
    if (!spanElement) {
      return;
    }

    // This is summary-body project-name-code class name
    const parentDivElement = parentDivRef.current;
    if (!parentDivElement) {
      return;
    }

    // Will set tooltip state true when hovering on the name (Tool-tip shows)
    const handleMouseEnter = () => {
      setIsToolTipVisible(true);
    };

    // Will set tooltip state false when leaving out the name (Tool-tip disappears)
    const handleMouseLeave = () => {
      setIsToolTipVisible(false);
    };

    const { scrollHeight, clientHeight } = divElement;

    // hide the tool-tip in case of it is not a long project name
    if (scrollHeight <= clientHeight) {
      // Disable the tooltip
      textSpanRef.current.style.display = "none";
      return;
    }

    // For the long name projects:
    // add mouse listener for the div that includes a long project name
    parentDivElement.addEventListener("mouseenter", handleMouseEnter);
    parentDivElement.addEventListener("mouseleave", handleMouseLeave);
  });

  return (
    <div
      ref={parentDivRef}
      className="project-name-container"
      data-testid={text}
    >
      <div ref={textDivRef} className="project-name">
        {text}
      </div>
      <span ref={textSpanRef} className="tooltip-text">
        {text}
      </span>
    </div>
  );
};

export const addCommas = (num: number): string => {
  // add commas between digits
  return num.toLocaleString("ja-JP");
};

export interface ReportTableProps {
  projectData: Project;
  onClick: () => void;
  isExpanded: boolean;
}

const ProjectSummary = forwardRef<HTMLDivElement, ReportTableProps>(
  ({ projectData, onClick, isExpanded }: ReportTableProps, ref) => {
    const {
      biid,
      name,
      code,
      sales,
      costOfGoodsSold,
      grossProfit,
      grossProfitRateString,
      operatingProfit,
      operatingProfitRateString,
    }: Project = projectData;
    const summaryArray = [
      addCommas(sales),
      addCommas(costOfGoodsSold),
      addCommas(grossProfit),
      `${grossProfitRateString}%`,
      addCommas(operatingProfit),
      `${operatingProfitRateString}%`,
    ];

    // storing the state of tool-tip. It is:
    // - true when mouse is hovering on the long project name and tool-tip shows
    // - false when mouse leave or is not hovering on the long project name
    const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

    // We don't want summary to be sticky when it is not expanded - it doesn't look good
    const summaryClass = isExpanded ? "summary-body-sticky" : "";
    // If Tool-tip is displaying (mouse is hovering on the long project name) then
    // make the summary-body has the same z-index level with the summary header (z-index: 20)
    // FYI: See the level of z-index in the normal state (tool-tip is not displaying) in About_z-index.md file
    const summaryStyle = {
      zIndex: isTooltipVisible ? 20 : "",
    };
    return (
      <button
        type="button"
        onClick={onClick}
        role="menubar"
        className="summary-row"
      >
        <div
          className={`summary-body project-name-code ${summaryClass}`}
          ref={ref}
          style={summaryStyle}
        >
          <ProjectNameTooltip
            text={name}
            setIsToolTipVisible={setIsTooltipVisible}
          />
          <div className="project-code">{code}</div>
        </div>
        {summaryArray.map((value, key) => {
          return (
            <div
              className={`summary-body summary-value ${summaryClass}`}
              key={biid + key.toString()}
            >
              {value}
            </div>
          );
        })}
        <div
          className={`summary-body drop-down-icon-container ${summaryClass}`}
        >
          <img
            className="drop-down-icon"
            src={isExpanded ? OpenSummaryIcon : CloseSummaryIcon}
            alt="drop-down-icon"
          />
        </div>
      </button>
    );
  }
);

export default ProjectSummary;
