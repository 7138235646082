import { useMemo } from "react";
import { ItemWithCategory } from "../../../api/getItemWithCategory";
import { ItemOption, ItemWithCategoryOption } from "./ItemFilter";
import { ItemWithSubItems, SubItemsDetails } from "../../../api/getSubItems";

const parseItemOptions = (data?: ItemWithCategory[]) => {
  const result: ItemWithCategoryOption[] = [];
  data?.forEach((category: ItemWithCategory) => {
    // get the options array from category
    const currentItemOptions: ItemOption[] = category.items.map((item) => {
      return {
        value: item.id.toString(),
        label: item.name,
        name_for_search: item.name_for_search,
        identification_code: item.code,
        sub_items: [],
      };
    });
    const CurrentCategory: ItemWithCategoryOption = {
      category: category.grouped_name,
      options: currentItemOptions,
    };
    result.push(CurrentCategory);
  });
  return result;
};

const parseSubItemOptions = (
  itemWithCategoryOptions: ItemWithCategoryOption[],
  data?: ItemWithSubItems[]
) => {
  const result: ItemWithCategoryOption[] = itemWithCategoryOptions;
  // Make a key value map from data
  const subItemMap = new Map<number, SubItemsDetails[]>();
  data?.forEach((subItem) => {
    if (subItem.sub_items.length > 0) {
      subItemMap.set(subItem.item_id, subItem.sub_items);
    }
  });

  itemWithCategoryOptions.forEach((category) => {
    category.options.forEach((item) => {
      const subItems = subItemMap.get(parseInt(item.value, 10));
      if (subItems !== undefined) {
        subItems.forEach((subItem) => {
          item.sub_items.push({
            value: `${item.value}_${subItem.id.toString()}`,
            label: subItem.name,
            name_for_search: subItem.name_for_search,
            identification_code: subItem.code,
            sub_items: [],
          });
        });
        item.sub_items.push({
          value: `${item.value}_no-sub-item`,
          label: "補助科目なし",
          name_for_search: "補助科目なし",
          identification_code: "",
          sub_items: [],
        });
      }
    });
  });
  return result;
};

export const useItemAndSubItemOptions = (
  itemData?: ItemWithCategory[],
  subItemData?: ItemWithSubItems[]
) => {
  const itemWithCategoryOptions = parseItemOptions(itemData);
  return useMemo(() => {
    return parseSubItemOptions(itemWithCategoryOptions, subItemData);
  }, [itemWithCategoryOptions, subItemData]);
};
