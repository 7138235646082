import { Dialog, Modal } from "react-aria-components";
import React, { ReactNode } from "react";
import "./ReactAreaModal.scss";

interface ModalComponentProps {
  modalStatus: boolean;
  setModalStatus: (value: boolean) => void;
  children: ReactNode;
}
const ReactAreaModalComponent = ({
  modalStatus,
  setModalStatus,
  children,
}: ModalComponentProps) => {
  return (
    <Modal isDismissable isOpen={modalStatus} onOpenChange={setModalStatus}>
      <Dialog aria-label="react-area-dialog-box">{children}</Dialog>
    </Modal>
  );
};

export default ReactAreaModalComponent;
