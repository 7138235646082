// FilterState: interface for all the selected data for filter of project report, like journal filter, department filter etc.
export interface FilterState {
  startDate: string | null;
  endDate: string | null;
  journalTypes: string[];
  journalStatuses: string[];
  departments: string[];
  businessPartners: string[];
  itemIDs: string[];
  subItemIDs: string[];
  projectBIIDs: string[];
  displaySubItems: string;
  taxFilter: string;
}

// Reducer state for project report
export interface State {
  filterState: FilterState;
  page: number;
  expandedProjects: Set<string>;
}

export interface UpdateFilterStateAction {
  type: "UPDATE_FILTER_STATE";
  payload: { filterState: FilterState };
}

export interface UpdatePageAction {
  type: "UPDATE_PAGE";
  payload: { page: number };
}

export interface UpdateExpandedProjectsAction {
  type: "UPDATE_EXPANDED_PROJECTS";
  payload: { expandedProjects: Set<string> };
}

export type ProjectReportAction =
  | UpdateFilterStateAction
  | UpdatePageAction
  | UpdateExpandedProjectsAction;

// reducer for project report
export function useProjectReportReducer(
  state: State,
  action: ProjectReportAction
): State {
  switch (action.type) {
    case "UPDATE_FILTER_STATE":
      return {
        ...state,
        filterState: action.payload.filterState,
      };
    case "UPDATE_PAGE":
      return {
        ...state,
        page: action.payload.page,
      };
    case "UPDATE_EXPANDED_PROJECTS":
      return {
        ...state,
        expandedProjects: action.payload.expandedProjects,
      };
    default:
      return state;
  }
}
