export interface BusinessPartner {
  id: number;
  encrypted_id: string;
  name: string;
  name_for_search: string;
  code: string;
}
export async function fetchBusinessPartners() {
  return fetch("/api/business_partners?include_no_biz_partner=true")
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      return response.json() as Promise<BusinessPartner[]>;
    })
    .catch((error) => {
      throw error;
    });
}
