import React, { useMemo } from "react";
import { Separator, Text } from "react-aria-components";
import {
  allOptionsSelected,
  GetFilterButtonCounter,
} from "../../CommonComponents/FilterButtonWithMenu/GetFilterButtonText";
import { useSearch } from "../../CommonComponents/SearchField/useSearch";
import { useSelectedWithSelectAllLogic } from "../../CommonComponents/FilterButtonWithMenu/useSelectedWithSelectAllLogic";
import { FilterButtonWithMenu } from "../../CommonComponents/FilterButtonWithMenu/FilterButtonWithMenu";
import SearchField from "../../CommonComponents/SearchField/SearchField";
import {
  FilterItem,
  FilterMenu,
  FilterSection,
} from "../../CommonComponents/FilterButtonWithMenu/FilterMenu";
import { NoOptionsText } from "../Helpers/NoOptionsText";
import { hasSelection } from "../Helpers/useFilterConfirmedState";
import { FilterSelection } from "../Helpers/FilterSelection";
import { useInfiniteScroll } from "../../CommonComponents/FilterButtonWithMenu/useInfiniteScroll";

export interface ItemWithCategoryOption {
  category: string;
  options: ItemOption[];
}

export interface ItemOption {
  value: string;
  label: string;
  name_for_search: string;
  identification_code: string;
  sub_items: ItemOption[];
}

// Deconstructed ItemWithCategoryOption to ItemOption
export const getAllOptionsFlat = (options: ItemWithCategoryOption[]) => {
  return options
    .map((ItemWithCategory) => {
      return ItemWithCategory.options;
    })
    .flat();
};

// Reconstructed filteredOptions to ItemWithCategoryOption
const getFilteredItemWithCategoryOptions = (
  ItemWithCategoryOptions: ItemWithCategoryOption[],
  filteredOptions: ItemOption[]
) => {
  const result: ItemWithCategoryOption[] = [];
  ItemWithCategoryOptions.forEach((category) => {
    const currentCategory: ItemWithCategoryOption = {
      category: category.category,
      options: [],
    };
    const filteredOptionsSet = new Set(filteredOptions);
    const currentOptions: ItemOption[] = category.options.filter((option) => {
      return filteredOptionsSet.has(option);
    });
    if (currentOptions.length > 0) {
      currentCategory.options = currentOptions;
      result.push(currentCategory);
    }
  });

  return result;
};
export const ItemFilter = ({
  options,
  confirmedSelection,
  setConfirmedSelection,
}: {
  options: ItemWithCategoryOption[];
  confirmedSelection: FilterSelection;
  setConfirmedSelection: (state: Set<React.Key>) => void;
}) => {
  const allOptionsFlat = useMemo(() => {
    return getAllOptionsFlat(options);
  }, [options]);
  const filterStateText = GetFilterButtonCounter(confirmedSelection);
  const [filteredOptions, onSearch, clearSearch, searchHasFocus, setFocus] =
    useSearch(allOptionsFlat);
  const [selected, onSelectionWithSelectAll] = useSelectedWithSelectAllLogic(
    filteredOptions.map((option) => option.value),
    confirmedSelection
  );

  const filteredItemWithCategoryOptions = useMemo(
    () => getFilteredItemWithCategoryOptions(options, filteredOptions),
    [options, filteredOptions]
  );

  const onSelectionConfirm = () => {
    clearSearch();
    setConfirmedSelection(selected);
  };

  const { visibleOptions, onHandleScroll } =
    useInfiniteScroll<ItemWithCategoryOption>(filteredItemWithCategoryOptions);

  return (
    <FilterButtonWithMenu
      buttonLabel="勘定科目"
      buttonText={filterStateText}
      showClearIcon={hasSelection(confirmedSelection)}
      onClearSelection={() => {
        setConfirmedSelection(new Set());
        onSelectionWithSelectAll(new Set());
      }}
      onSelectionConfirm={onSelectionConfirm}
    >
      <SearchField
        placeholderText="勘定科目名、コード、検索キー"
        onChange={onSearch}
        setFocus={setFocus}
      />
      {filteredItemWithCategoryOptions.length > 0 ? (
        <FilterMenu
          // Important! This is preventing from losing focus on search field when results reappear
          autoFocus={!searchHasFocus}
          selectionMode="multiple"
          selectedKeys={selected}
          onSelectionChange={onSelectionWithSelectAll}
          onScroll={onHandleScroll}
        >
          <FilterItem id="all" key="all" removeBackgroundForSelected>
            <Text slot="label">{allOptionsSelected}</Text>
          </FilterItem>
          <Separator />
          {visibleOptions.map((filteredCategory, key) => {
            return (
              <React.Fragment key={filteredCategory.category + key.toString()}>
                <FilterSection category={filteredCategory.category}>
                  {filteredCategory.options.map((option) => {
                    return (
                      <FilterItem id={option.value} key={option.value}>
                        <Text slot="label">{option.label}</Text>
                        <Text slot="description">
                          {option.identification_code}
                        </Text>
                      </FilterItem>
                    );
                  })}
                </FilterSection>
                <Separator />
              </React.Fragment>
            );
          })}
        </FilterMenu>
      ) : (
        <NoOptionsText />
      )}
    </FilterButtonWithMenu>
  );
};
