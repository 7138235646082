export interface TermMonth {
  start_date: string;
  end_date: string;
}
export interface TermDetails {
  encrypted_id: string;
  start_date: string;
  end_date: string;
  term_months: TermMonth[];
  include_tax_id: string;
}

export async function fetchTermDetails() {
  return fetch("/api/terms/current_term")
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      return response.json() as Promise<TermDetails>;
    })
    .catch((error) => {
      throw error;
    });
}
