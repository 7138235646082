import React from "react";
import { Selection } from "react-aria-components";
import {
  checkIfAllOptionsSelected,
  removeValues,
} from "../../CommonComponents/FilterButtonWithMenu/useSelectedWithSelectAllLogic";

export function LocalFilterStateToConfirmedFilterState(
  values: React.Key[],
  selected: Set<React.Key>
): Selection {
  if (checkIfAllOptionsSelected(values, selected)) {
    return "all";
  }
  if (selected.has("all")) {
    return removeValues(selected, ["all"]);
  }
  return selected;
}
