import React, { useEffect, useState } from "react";
import {
  fetchDefaultJournalStatuses,
  putDefaultJournalStatuses,
} from "../../../api/defaultJournalStatus";

export const useFetchDefaultJournalStatus = () => {
  const [defaultJournalStatus, setDefaultJournalStatus] = useState<
    Set<React.Key>
  >(new Set());
  useEffect(() => {
    fetchDefaultJournalStatuses()
      .then((data) => {
        const defaultData: Set<string> = new Set(
          data?.statuses.map((status) => status.toString())
        );
        setDefaultJournalStatus(defaultData);
      })
      .catch((error) => {
        console.log("error while fetching the default journal status:", error);
      });
  }, []);
  return defaultJournalStatus;
};

const preprocessDefaultJournalStatuses = (
  defaultJournalStatuses: Set<React.Key>
) => {
  return [...defaultJournalStatuses].map((status) => {
    return parseInt(status.toString(), 10);
  });
};

export const putDefaultJournalStatusToDb = (
  defaultJournalStatuses: Set<React.Key>,
  onSuccess: () => void,
  onError: (error: Error) => void
) => {
  putDefaultJournalStatuses(
    preprocessDefaultJournalStatuses(defaultJournalStatuses)
  )
    .then((response) => {
      if (response.ok) {
        onSuccess();
      } else {
        onError(new Error("Failed to save data to the database."));
      }
    })
    .catch((error) => {
      console.log("error while putting the default journal status:", error);
      onError(error as Error);
    });
};
