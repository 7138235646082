import React, { ChangeEvent, useEffect, useState } from "react";
import RadioButton from "../../CommonComponents/RadioButton/RadioButton";
import "./DisplaySubItemFilter.css";

interface DisplaySubItemFilterProps {
  selectedOption: string;
  setDisplaySubItemGlobal: (displaySubItem: string) => void;
}

const DisplaySubItemFilter = ({
  selectedOption,
  setDisplaySubItemGlobal,
}: DisplaySubItemFilterProps) => {
  const [displaySubItem, setDisplaySubItem] = useState<string>(
    selectedOption || "true"
  );
  useEffect(() => {
    setDisplaySubItem(selectedOption || "true");
  }, [selectedOption]);

  // update the global displaySubItem with the current displaySubItem
  useEffect(() => {
    setDisplaySubItemGlobal(displaySubItem);
  }, [setDisplaySubItemGlobal, displaySubItem]);

  const handleDisplaySubItemOptionChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setDisplaySubItem(event.target.value);
  };

  return (
    <div className="sub-item-display-box">
      <p>補助科目：</p>
      <div className="subitem-display-options">
        <RadioButton
          label="表示"
          value="true"
          isChecked={displaySubItem === "true"}
          onRadioChange={handleDisplaySubItemOptionChange}
        />

        <RadioButton
          label="非表示"
          value="false"
          isChecked={displaySubItem === "false"}
          onRadioChange={handleDisplaySubItemOptionChange}
        />
      </div>
    </div>
  );
};

export default DisplaySubItemFilter;
